import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { ApiService } from "../services/api.service";
import NotificationService from '../services/notification.service';
import { Game } from "../models/Game";
import { Leaderboard } from "../models/Leaderboard";
import { Router } from "@angular/router";
import * as XLSX from 'xlsx'; 

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.css"],
})
export class EventsComponent implements OnInit {
  public cloudfrontOpenURL: any;
  public allGamesUrl: string = "/api/admin/v1/all/eventGames";
  public categoriesUrl: string = "/api/admin/v1/all/categories";
  public games: any = [];
  public filteredGames: any = [];
  public updateEdit = false;
  public gameToBeEditedTitle: string;
  public idToBeCancelled: number = 0;
  public filterCategoryId: number = -1;
  public viewEvent: Game = {
    id: 0,
    title: "",
    speed: 0,
    category_id: 0,
    date_added: "",
    order_id: 0,
    image: "",
    briefing: "",
    review: "",
    data_file_cdn_object: "",
    briefing_cdn_object: "",
    review_cdn_object: "",
    visible: 0,
    max_results_to_show: 0,
  };
  public editGame: Game = {
    id: 0,
    title: "",
    speed: 0,
    category_id: 0,
    order_id: 0,
    image: "",
    briefing: "",
    review: "",
    data_file_cdn_object: "",
    briefing_cdn_object: "",
    review_cdn_object: "",
    visible: 0,
    max_results_to_show: 0,
  };
  public schedulingDatetimes = {
    startDatetime: "",
    endDatetime: "",
  };
  public schedulingDatetimesUTC = {
    id: 0,
    startDatetime: "",
    endDatetime: "",
  };
  public viewLeaderboard: any = [];
  public selectedEventEvents: any = [];
  public SchedulingForm;
  public updateViewEvent = false;
  public isCancellingEvent = false;
  public categories: any = [];
  public eventEdit;
  public searchText = "";
  public leaderboardTitle = "";
  public paramsToSend: any = {
    dbName: "",
    dbColumn: "",
    searchText: "",
    page: 1,
    categoryID: -1
  };
  public updateSubmitOpen = false;
  public isSchedulingGame = false;
  public leaderboardOpen = false;
  pager = {
    currentPage: undefined,
    pages: undefined,
    totalPages: undefined,
  };
  public pageOfItems = [];

  constructor(
    private api: ApiService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.getcloudfrontOpenURL();
    this.assignSubmitOpen();
    this.getCategories();
    this.eventEdit = new FormGroup({
      name: new FormControl(this.editGame.title, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(39),
      ]),
      orderID: new FormControl(this.editGame.title, [
        Validators.required,
        Validators.min(1),
      ]),
    });
    this.SchedulingForm = new FormGroup({
      startDatetime: new FormControl(this.schedulingDatetimes.startDatetime, [
        Validators.required,
      ]),
      endDatetime: new FormControl(this.schedulingDatetimes.endDatetime, [
        Validators.required,
      ]),
    });
  }

  scheduleEvent() {
    this.schedulingDatetimesUTC.startDatetime = new Date(this.schedulingDatetimes.startDatetime).toISOString();
    this.schedulingDatetimesUTC.endDatetime = new Date(this.schedulingDatetimes.endDatetime).toISOString();
    this.api
      .scheduleEvent(this.schedulingDatetimesUTC)
      .subscribe(async (res: { error }) => {
        if (res.error) {
          this.notificationService.notifyError(res.error);
        } else {
          this.notificationService.notifySuccess("Scheduled successfully");
        }
        this.assignSubmitOpen();
        this.closeScheduling();
      });
  }

  isScheduled(game) {
    return game.scheduled === 1;
  }

  convertToDateString(dateString) {
    return new Date(dateString).toString();
  }

  cancelScheduledEvent(id) {
    this.api.cancelScheduledEvent({ id }).subscribe(async (res) => {
      this.assignSubmitOpen();
      this.closeCancelEvent();
      this.notificationService.notifySuccess("Event successfully cancelled.");
    })
  }

  openCancelEvent(id) {
    this.idToBeCancelled = id;
    this.isCancellingEvent = true;
  }

  closeCancelEvent() {
    this.idToBeCancelled = 0;
    this.isCancellingEvent = false;
  }

  getCategories() {
    this.api.getAllCategories(this.categoriesUrl).subscribe(async (res) => {
      this.categories = res;
    });
  }

  getAllGames() {
    this.api.getAllGames(this.allGamesUrl).subscribe(async (res) => {
      this.games = res;
    });
  }

  schedulingGame(game) {
    this.isSchedulingGame = true;
    this.schedulingDatetimesUTC.id = game.id;
  }

  closeScheduling() {
    this.isSchedulingGame = false;
  }

  editingGame(game) {
    this.gameToBeEditedTitle = game.title;
    this.editGame = Object.assign({}, game);
    this.getAllGames();
    this.updateEdit = true;
  }

  closeEditing() {
    this.updateEdit = false;
  }

  updateGame({ value, valid }: { value: Game; valid: boolean }) {
    if (!valid) {
      this.notificationService.notifyError("Please fill out the form correctly");
    } else {
      return this.api
        .editEvent(this.editGame)
        .subscribe(async (res: { error }) => {
          if (res.error) {
            this.notificationService.notifyError(res.error);
          } else {
            this.notificationService.notifySuccess("Edit successful");
          }
          this.updateEdit = false;
          this.assignSubmitOpen();
        });
    }
  }

  openEvent(game) {
    this.viewEvent = game;
    this.getEventEvents(game.id);
    this.getAllGames();
    this.updateViewEvent = true;
  }

  getEventEvents(game) {
    return this.api.getGameEvents(game).subscribe(async (res) => {
      this.selectedEventEvents = res;
    });
  }

  closeEvent() {
    this.updateViewEvent = false;
  }

  isEventScheduled(game) {
    return game.start_time !== null;
  }

  async assignSubmitOpen() {
    this.updateSubmitOpen = true;
    this.paramsToSend.dbName = "games";
    this.paramsToSend.dbColumn = "title";
    this.paramsToSend.searchText = this.searchText;
    this.paramsToSend.page = 1;
    this.paramsToSend.categoryID = this.filterCategoryId;
    await this.getFilteredEvents();
  }

  getFilteredEvents() {
    return this.api
      .getFilteredEvents(this.paramsToSend)
      .subscribe(async (res) => {
        this.filteredGames = res;
        this.pager = this.filteredGames.pager;
        this.pageOfItems = this.filteredGames.pageOfItems;
      });
  }

  returnSearch() {
    return this.searchText;
  }

  nextPage() {
    this.paramsToSend.page++;
    this.getFilteredEvents();
  }

  previousPage() {
    if (this.paramsToSend.page >= 1) {
      this.paramsToSend.page--;
    }
    this.getFilteredEvents();
  }

  lastPage() {
    this.paramsToSend.page = this.pager.totalPages;
    this.getFilteredEvents();
  }

  firstPage() {
    this.paramsToSend.page = 1;
    this.getFilteredEvents();
  }

  selectedPage(page) {
    this.paramsToSend.page = page;
    this.getFilteredEvents();
  }

  getcloudfrontOpenURL() {
    return this.api.getCloudfrontOpenURL().subscribe(async (res) => {
      // @ts-ignore
      this.cloudfrontOpenURL = res.URL;
    });
  }

  openLeaderboard(game) {
    this.leaderboardTitle = game.title;
    this.leaderboardOpen = true;
    this.getLeaderboard(game.id);
  }

  getLeaderboard(game) {
    return this.api.getLeaderboard(game).subscribe(async (res) => {
      this.viewLeaderboard = res;
    });
  }

  closeLeaderboard() {
    this.leaderboardOpen = false;
  }

  exportExcel(leaderboardTitle: string){
    let element = document.getElementById('results-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, `${leaderboardTitle}-export.xlsx`);
  }
}
