import { Component, OnInit } from '@angular/core';
import {ApiService} from "../services/api.service";
import {Category} from '../models/Category';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from "@angular/router";
import {FlashMessagesService} from "angular2-flash-messages";

@Component({
  selector: 'app-appusers',
  templateUrl: './appusers.component.html',
  styleUrls: ['./appusers.component.css']
})
export class AppusersComponent implements OnInit {
  public allUsersUrl: string = '/api/admin/v1/all/users';
  public groupsUrl: string = '/api/admin/v1/all/groups';
  public schoolsUrl: string = '/api/admin/v1/all/schools';
  public users: any = [];
  public filteredUsers: any = [];
  public params: any = [
    {
      "value":"users.first_name",
      "label":"First Name"
    },
    {
      "value":"users.last_name",
      "label":"Last Name"
    },
    {
      "value":"users.login",
      "label":"Login"
    },
    {
      "value":"schools.title",
      "label":"School Name"
    },
    {
      "value":"groups.name",
      "label":"Group Name"
    }
  ];
  public editUser = {
    id: "",
    login: "",
    first_name: "",
    last_name: "",
    school_id: null,
    group_id: null,
    name: undefined,
    title: undefined
  }
  public paramSet: string = "";
  public paramChosen: string;
  public searchText: string = "";
  public userAndGroupID: any = {user_id: 0, group_id: 0};
  public updateAssignGroup = false;
  public updateSubmitOpen = false;
  public userAssignGroupTitle: string;
  public assignGroupUsers: any;
  public groups: any;
  public schools: any;
  public chosenGroupID: number;
  elements: any;
  private paramChosenSplit: string[];
  public paramsToSend: any = {dbName: "", dbColumn: "", searchText: "", page: 1};
  pager = {
    currentPage: undefined,
    pages: undefined,
    totalPages: undefined
  };
  pageOfItems = [];

  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.getUsers();
    this.getGroups();
    this.getSchools();
  }

  getUsers() {
    return this.api.getAllUsers(this.allUsersUrl).subscribe(async res => {
      this.users = res;
    })
  }

  getGroups() {
    return this.api.getAllGroups(this.groupsUrl).subscribe(async res => {
      this.groups = res;
    })
  }

  getSchools() {
    return this.api.getAllSchools(this.schoolsUrl).subscribe(async res => {
      this.schools = res;
    })
  }

  assignGroupOpen(usr) {
    this.userAssignGroupTitle = usr.login;
    this.assignGroupUsers = usr;
    this.getUsers();
    this.getGroups();
    this.updateAssignGroup = true;
  }

  assignGroupClose() {
    this.updateAssignGroup = false;
  }

  assignGroup() {
    this.userAndGroupID.user_id = this.assignGroupUsers.id;
    this.userAndGroupID.group_id = this.chosenGroupID;
    return this.api.assignUserGroup(this.userAndGroupID).subscribe(async (res) => {
      if (res) {
        this.updateAssignGroup = false;
        this.getFilteredUsers();
      }
    })
  };

   async assignSubmitOpen() {
     this.updateSubmitOpen = true;
     this.paramChosen = this.changeParam();
     this.paramChosenSplit = this.paramChosen.split('.', 2);
     this.paramsToSend.dbName = this.paramChosenSplit[0];
     this.paramsToSend.dbColumn = this.paramChosenSplit[1];
     this.paramsToSend.searchText = this.searchText;
     await this.getFilteredUsers();
   }

   getFilteredUsers() {
     return this.api.getFilteredUsers(this.paramsToSend).subscribe(async (res:any) => {
       this.filteredUsers = res;
       this.pager = this.filteredUsers.pager;
       this.pageOfItems = this.filteredUsers.pageOfItems;
     })
   }

   changeParam() {
     return this.paramSet;
   }

   returnSearch() {
     return this.searchText;
   }

  nextPage(){
    this.paramsToSend.page++;
    this.getFilteredUsers();
  }

  previousPage(){
     if(this.paramsToSend.page >= 1){
       this.paramsToSend.page--;
     }
    this.getFilteredUsers();
  }

  lastPage(){
    this.paramsToSend.page = this.pager.totalPages;
    this.getFilteredUsers();
  }

  firstPage(){
    this.paramsToSend.page = 1;
    this.getFilteredUsers();
  }

  selectedPage(page) {
    this.paramsToSend.page = page;
    this.getFilteredUsers();
  }
}
