<div class="container" style="margin-top: 70px">
  <div class="col-md-12 mb-4">
    <h1>Game Generator</h1>
  </div>
  <div class="container" style="margin-top: 15px">
    <div class="jumbotron">
      <div class="modal-body">
        <form #gameGenerateForm="ngForm">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Ticker: </label>
            <div class="col-sm-10">
              <input
                [ngClass]="{ 'is-invalid': ticker.errors && ticker.touched }"
                #ticker="ngModel"
                required
                minLength="1"
                maxLength="20"
                name="ticker"
                class="form-control"
                type="text"
                [(ngModel)]="newGame.ticker"
              />
              <div [hidden]="!ticker.errors?.required" class="invalid-feedback">
                Ticker is required.
              </div>
              <div
                [hidden]="!ticker.errors?.minLength"
                class="invalid-feedback"
              >
                Ticker must be at least 1 character long.
              </div>
              <div
                [hidden]="!ticker.errors?.maxLength"
                class="invalid-feedback"
              >
                Ticker must be max 19 characters long.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Date of Data: </label>
            <div class="col-sm-10">
              <input
                [ngClass]="{
                  'is-invalid': dateData.errors && dateData.touched
                }"
                #dateData="ngModel"
                required
                minLength="1"
                maxLength="20"
                name="dateData"
                class="form-control"
                type="date"
                [(ngModel)]="newGame.dateData"
              />
              <div
                [hidden]="!dateData.errors?.required"
                class="invalid-feedback"
              >
                Ticker is required.
              </div>
              <div
                [hidden]="!dateData.errors?.minLength"
                class="invalid-feedback"
              >
                Ticker must be at least 1 character long.
              </div>
              <div
                [hidden]="!dateData.errors?.maxLength"
                class="invalid-feedback"
              >
                Ticker must be max 19 characters long.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label"
              >Candlestick Duration:
            </label>
            <div class="col-sm-10">
              <input
                [ngClass]="{
                  'is-invalid':
                    CandleStickDuration.errors && CandleStickDuration.touched
                }"
                #CandleStickDuration="ngModel"
                required
                name="CandleStickDuration"
                min="1"
                class="form-control"
                type="number"
                appForbiddenName="-"
                [(ngModel)]="newGame.candleStickDuration"
              />
              <div
                [hidden]="!CandleStickDuration.errors?.required"
                class="invalid-feedback"
              >
                Candlestick duration is required.
              </div>
              <div
                [hidden]="!CandleStickDuration.errors?.min"
                class="invalid-feedback"
              >
                Candlestick duration must be greater than 0.
              </div>
              <div
                [hidden]="!CandleStickDuration.errors?.forbiddenName"
                class="invalid-feedback"
              >
                Candlestick duration cannot be negative.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Rounding Mode: </label>
            <div class="col-sm-10">
              <input
                [ngClass]="{
                  'is-invalid': roundingMode.errors && roundingMode.touched
                }"
                #roundingMode="ngModel"
                required
                name="roundingMode"
                min="1"
                class="form-control"
                type="number"
                appForbiddenName="-"
                [(ngModel)]="newGame.roundingMode"
              />
              <div
                [hidden]="!roundingMode.errors?.required"
                class="invalid-feedback"
              >
                Rounding Mode is required.
              </div>
              <div
                [hidden]="!roundingMode.errors?.min"
                class="invalid-feedback"
              >
                Rounding Mode must be greater than 0.
              </div>
              <div
                [hidden]="!roundingMode.errors?.forbiddenName"
                class="invalid-feedback"
              >
                Rounding Mode cannot be negative.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">FPS: </label>
            <div class="col-sm-10">
              <input
                [ngClass]="{ 'is-invalid': FPS.errors && FPS.touched }"
                #FPS="ngModel"
                required
                name="FPS"
                min="1"
                class="form-control"
                type="number"
                appForbiddenName="-"
                [(ngModel)]="newGame.FPS"
              />
              <div [hidden]="!FPS.errors?.required" class="invalid-feedback">
                FPS is required.
              </div>
              <div [hidden]="!FPS.errors?.min" class="invalid-feedback">
                FPS must be greater than 0.
              </div>
              <div
                [hidden]="!FPS.errors?.forbiddenName"
                class="invalid-feedback"
              >
                FPS cannot be negative.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Max Position: </label>
            <div class="col-sm-10">
              <input
                [ngClass]="{
                  'is-invalid': maxPosition.errors && maxPosition.touched
                }"
                #maxPosition="ngModel"
                required
                name="maxPosition"
                min="1"
                class="form-control"
                type="number"
                appForbiddenName="-"
                [(ngModel)]="newGame.maxPosition"
              />
              <div
                [hidden]="!maxPosition.errors?.required"
                class="invalid-feedback"
              >
                Max Position is required.
              </div>
              <div [hidden]="!maxPosition.errors?.min" class="invalid-feedback">
                Max Position must be greater than 0.
              </div>
              <div
                [hidden]="!maxPosition.errors?.forbiddenName"
                class="invalid-feedback"
              >
                Max Position cannot be negative.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Scaling Factor: </label>
            <div class="col-sm-10">
              <input
                [ngClass]="{ 'is-invalid': scaling.errors && scaling.touched }"
                #scaling="ngModel"
                required
                name="maxPosition"
                min="0"
                class="form-control"
                type="number"
                appForbiddenName="-"
                [(ngModel)]="newGame.scaling"
              />
              <div
                [hidden]="!scaling.errors?.required"
                class="invalid-feedback"
              >
                Max Position is required.
              </div>
              <div [hidden]="!scaling.errors?.min" class="invalid-feedback">
                Max Position must be greater than 0.
              </div>
              <div
                [hidden]="!scaling.errors?.forbiddenName"
                class="invalid-feedback"
              >
                Max Position cannot be negative.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label" style="max-width: 6%">
              Game Timezone:
            </label>
            <div class="col-sm-9">
              <ng-moment-timezone-picker
                (onselect)="setTimeZone($event)"
                [getUserZone]="true"
              >
              </ng-moment-timezone-picker>
            </div>
          </div>
          <div
            *ngFor="let series of newSeries; let i = index"
            [attr.data-index]="i"
          >
            <hr />
            <p>Date format example: 2020-04-23T10:00:00Z</p>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Starting Time: </label>
              <div class="col-sm-10">
                <input
                  required
                  name="startingTime-{{ i }}"
                  class="form-control"
                  type="text"
                  [(ngModel)]="series.dateFrom"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Ending Time: </label>
              <div class="col-sm-10">
                <input
                  required
                  name="endingTime-{{ i }}"
                  class="form-control"
                  type="text"
                  [(ngModel)]="series.dateTo"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Realtime Seconds: </label>
              <div class="col-sm-10">
                <input
                  required
                  name="realTimeSeconds-{{ i }}"
                  class="form-control"
                  type="number"
                  min="1"
                  appForbiddenName="-"
                  [(ngModel)]="series.realTimeSeconds"
                />
              </div>
            </div>
            <hr />
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">CQG Data File: </label>
            <div class="col-sm-10">
              <form [formGroup]="uploadForm">
                <input
                  required
                  name="gameData"
                  class="form-control"
                  type="file"
                  accept=".ts"
                  (change)="onFileSelect($event)"
                />
              </form>
            </div>
          </div>
          <div class="formgroup">
            <ul class="form-buttons">
              <li>
                <button
                  class="btn btn-success"
                  (click)="uploadFile(gameGenerateForm)"
                >
                  <span *ngIf="!creating">Create</span>
                  <div
                    *ngIf="creating"
                    class="spinner-border"
                    style="width: 1.5rem; height: 1.5rem"
                  >
                    <span class="sr-only"></span>
                  </div>
                </button>
              </li>
              <li>
                <button
                  class="btn btn-danger float-right"
                  style="margin-left: 10px"
                  (click)="deleteNewSeries()"
                >
                  Remove Last Event
                </button>
              </li>
              <li>
                <button
                  class="btn btn-danger float-right"
                  (click)="addNewSeries()"
                >
                  Add Event
                </button>
              </li>
            </ul>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">JSON File: </label>
            <div class="col-sm-9">
              <textarea
                type="text"
                rows="5"
                readonly
                class="form-control-plaintext"
                value="{{ finalData }}"
              >
              </textarea>
            </div>
            <button
              class="btn btn-success"
              *ngIf="downloadAvailable"
              (click)="downloadFile()"
            >
              <span>Download JSON</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
