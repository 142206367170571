<div class="custom-modal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div [ngSwitch]="action" class="modal-header">
        <h2 class="modal-title" *ngSwitchCase="ACTIONS.CREATE">
          Create New Game
        </h2>
        <h2 class="modal-title" *ngSwitchCase="ACTIONS.EDIT">
          Editing: {{ title }}
        </h2>
        <h2 class="modal-title" *ngSwitchCase="ACTIONS.VIEW">
          Viewing: {{ title }}
        </h2>
        <h2 class="modal-title" *ngSwitchCase="ACTIONS.DUPLICATE">
          Duplicate: {{ title }}
        </h2>
        <button type="button" class="btn btn-danger" (click)="close()">
          Close
        </button>
      </div>
      <div class="modal-body">
        <form #GameForm="ngForm">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Name: </label>
            <div class="col-sm-10">
              <input
                [ngClass]="{ 'is-invalid': name.errors && name.touched }"
                #name="ngModel"
                required
                minLength="4"
                maxLength="39"
                name="name"
                class="form-control"
                type="text"
                [readonly]="isViewAction()"
                [(ngModel)]="title"
              />
              <div [hidden]="!name.errors?.required" class="invalid-feedback">
                Game name is required.
              </div>
              <div [hidden]="!name.errors?.minLength" class="invalid-feedback">
                Game name must be at least 4 characters long.
              </div>
              <div [hidden]="!name.errors?.maxLength" class="invalid-feedback">
                Game name must be max 40 characters long.
              </div>
            </div>
          </div>
          <div
            class="form-group row"
            *ngIf="isViewAction() && cloudfrontOpenURL"
          >
            <label class="col-sm-2 col-form-label">Cover Image: </label>
            <div class="col-sm-10">
              <img
                src="https://{{ cloudfrontOpenURL }}/games/{{ image }}"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Image Path: </label>
            <div class="col-sm-8">
              <input
                [ngClass]="{
                  'is-invalid': imagePath.errors && imagePath.touched
                }"
                [disabled]="title === ''"
                #imagePath="ngModel"
                required
                name="imagePath"
                class="form-control"
                type="text"
                [readonly]="isViewAction() || isDuplicateAction()"
                [(ngModel)]="image"
              />
            </div>
            <div class="col-sm-2" *ngIf="!isViewAction() && !isDuplicateAction()">
              <input
                #imageInput
                [disabled]="title === ''"
                type="file"
                accept="image/*"
                class="form-control"
                id="imageInput"
                (change)="selectContent('image', imageInput, false)"
              />
              <label
                class="custom-file-label"
                style="cursor: pointer"
                for="imageInput"
              ></label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Briefing: </label>
            <div class="col-sm-10">
              <textarea
                autosize
                [ngClass]="{
                  'is-invalid': briefingDesc.errors && briefingDesc.touched
                }"
                #briefingDesc="ngModel"
                required
                name="briefingDesc"
                class="form-control"
                type="text"
                [readonly]="isViewAction()"
                [(ngModel)]="briefing"
              >
              </textarea>
              <div
                [hidden]="!briefingDesc.errors?.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Review: </label>
            <div class="col-sm-10">
              <textarea
                autosize
                [ngClass]="{
                  'is-invalid': reviewDesc.errors && reviewDesc.touched
                }"
                #reviewDesc="ngModel"
                required
                name="reviewDesc"
                class="form-control"
                type="text"
                [readonly]="isViewAction()"
                [(ngModel)]="review"
              >
              </textarea>
              <div
                [hidden]="!reviewDesc.errors?.required"
                class="invalid-feedback"
              >
                Review is required
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Game Path: </label>
            <div class="col-sm-8">
              <input
                [ngClass]="{
                  'is-invalid': gamePath.errors && gamePath.touched
                }"
                [disabled]="title === ''"
                #gamePath="ngModel"
                required
                name="gamePath"
                class="form-control"
                type="text"
                [readonly]="isViewAction() || isDuplicateAction()"
                [(ngModel)]="data_file_cdn_object"
              />

              <div
                [hidden]="!gamePath.errors?.required"
                class="invalid-feedback"
              >
                Game file path required
              </div>
            </div>
            <div class="col-sm-2" *ngIf="!isViewAction() && !isDuplicateAction()">
              <input
                #gameInput
                [disabled]="title === ''"
                type="file"
                accept="application/JSON"
                class="form-control"
                id="gameInput"
                (change)="
                  selectContent('data_file_cdn_object', gameInput, true)
                "
              />
              <label
                class="custom-file-label"
                style="cursor: pointer"
                for="gameInput"
              ></label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Briefing Path: </label>
            <div class="col-sm-8">
              <input
                [ngClass]="{
                  'is-invalid': briefingPath.errors && briefingPath.touched
                }"
                [disabled]="title === ''"
                #briefingPath="ngModel"
                required
                name="briefingPath"
                class="form-control"
                type="text"
                [readonly]="isViewAction() || isDuplicateAction()"
                [(ngModel)]="briefing_cdn_object"
              />

              <div
                [hidden]="!briefingPath.errors?.required"
                class="invalid-feedback"
              >
                Briefing file path required
              </div>
            </div>
            <div class="col-sm-2" *ngIf="!isViewAction() && !isDuplicateAction()">
              <input
                #briefingInput
                [disabled]="title === ''"
                type="file"
                accept="video/*"
                class="form-control"
                id="briefingInput"
                (change)="
                  selectContent('briefing_cdn_object', briefingInput, true)
                "
              />
              <label
                class="custom-file-label"
                style="cursor: pointer"
                for="briefingInput"
              ></label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Review Path: </label>
            <div class="col-sm-8">
              <input
                [ngClass]="{
                  'is-invalid': reviewPath.errors && reviewPath.touched
                }"
                [disabled]="title === ''"
                #reviewPath="ngModel"
                required
                name="reviewPath"
                class="form-control"
                type="text"
                [readonly]="isViewAction() || isDuplicateAction()"
                [(ngModel)]="review_cdn_object"
              />

              <div
                [hidden]="!reviewPath.errors?.required"
                class="invalid-feedback"
              >
                Review file path required
              </div>
            </div>
            <div class="col-sm-2" *ngIf="!isViewAction() && !isDuplicateAction()">
              <input
                #reviewInput
                [disabled]="title === ''"
                type="file"
                accept="video/*"
                class="form-control"
                id="reviewInput"
                (change)="selectContent('review_cdn_object', reviewInput, true)"
              />
              <label
                class="custom-file-label"
                style="cursor: pointer"
                for="reviewInput"
              ></label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Visibility: </label>
            <div class="col-sm-10">
              <select
                name="visibility"
                id="visibilityNewSelection"
                class="custom-select"
                [disabled]="isViewAction() || isDuplicateAction()"
                [(ngModel)]="visible"
              >
                <option value="1">Visible</option>
                <option value="0">Not Visible</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Results Max: </label>
            <div class="col-sm-10">
              <input
                [ngClass]="{
                  'is-invalid': max_results.errors && max_results.touched
                }"
                #max_results="ngModel"
                required
                name="resultsMax"
                class="form-control"
                min="1"
                type="number"
                appForbiddenName="-"
                [readonly]="isViewAction() || isDuplicateAction()"
                [(ngModel)]="max_results_to_show"
              />

              <div
                [hidden]="!max_results.errors?.required"
                class="invalid-feedback"
              >
                Max Results required
              </div>
              <div [hidden]="!max_results.errors?.min" class="invalid-feedback">
                Max Results must be greater than 0.
              </div>
              <div
                [hidden]="!max_results.errors?.forbiddenName"
                class="invalid-feedback"
              >
                Max Results cannot be negative.
              </div>
            </div>
          </div>
          <div
            *ngFor="let eventDetails of events; let i = index"
            [attr.data-index]="i"
          >
            <hr />
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Event News: </label>
              <div class="col-sm-10">
                <textarea
                  autosize
                  required
                  name="eventNewsCreate-{{ i }}"
                  class="form-control"
                  type="text"
                  [readonly]="isViewAction()"
                  [(ngModel)]="eventDetails.text"
                >
                </textarea>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Event Time: </label>
              <div class="col-sm-10">
                <input
                  required
                  name="eventTimeCreate-{{ i }}"
                  class="form-control"
                  type="time"
                  [readonly]="isViewAction() || isDuplicateAction()"
                  [(ngModel)]="eventDetails.event_time"
                  step="1"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Event Voice: </label>
              <div class="col-sm-8">
                <input
                  required
                  name="eventPathCreate-{{ i }}"
                  class="form-control"
                  type="text"
                  [disabled]="title === ''"
                  [readonly]="isViewAction() || isDuplicateAction()"
                  [(ngModel)]="eventDetails.cdn_object"
                />
              </div>
              <div class="col-sm-2" *ngIf="!isViewAction() && !isDuplicateAction()">
                <input
                  #eventInput
                  [disabled]="title === ''"
                  type="file"
                  accept="video/*"
                  class="form-control"
                  id="eventInput-{{ i }}"
                  (change)="selectContent('cdn_object', eventInput, true, i)"
                />
                <label
                  class="custom-file-label"
                  style="cursor: pointer"
                  for="eventInput-{{ i }}"
                ></label>
              </div>
            </div>
          </div>
          <div class="formgroup">
            <ul class="form-buttons">
              <li [ngSwitch]="action">
                <button
                  class="btn btn-success"
                  *ngSwitchCase="ACTIONS.CREATE"
                  (click)="createNewGame(GameForm)"
                  [disabled]="!GameForm.form.valid"
                >
                  <span *ngIf="!creating">Create</span>
                  <div
                    *ngIf="creating"
                    class="spinner-border"
                    style="width: 1.5rem; height: 1.5rem"
                  >
                    <span class="sr-only"></span>
                  </div>
                </button>
                <button
                  class="btn btn-success"
                  *ngSwitchCase="ACTIONS.EDIT"
                  (click)="editGame(GameForm)"
                  [disabled]="!GameForm.form.valid"
                >
                  <span *ngIf="!creating">Update</span>
                  <div
                    *ngIf="creating"
                    class="spinner-border"
                    style="width: 1.5rem; height: 1.5rem"
                  >
                    <span class="sr-only"></span>
                  </div>
                </button>
                <button
                  class="btn btn-success"
                  *ngSwitchCase="ACTIONS.DUPLICATE"
                  (click)="createNewGame(GameForm)"
                  [disabled]="!GameForm.form.valid"
                >
                  <span *ngIf="!creating">Duplicate</span>
                  <div
                    *ngIf="creating"
                    class="spinner-border"
                    style="width: 1.5rem; height: 1.5rem"
                  >
                    <span class="sr-only"></span>
                  </div>
                </button>
              </li>
              <li>
                <button
                  class="btn btn-danger float-right"
                  *ngIf="!isViewAction() && !isDuplicateAction()"
                  [disabled]="events.length === 0"
                  style="margin-left: 10px"
                  (click)="deleteGameEvent()"
                >
                  Remove Last Event
                </button>
              </li>
              <li>
                <button
                  class="btn btn-primary float-right"
                  *ngIf="!isViewAction() && !isDuplicateAction()"
                  (click)="addEvent()"
                >
                  Add Event
                </button>
              </li>
              <li>
                <button
                  class="btn btn-primary float-right"
                  *ngIf="!isViewAction() && !isDuplicateAction()"
                  (click)="editEvent()"
                >
                  Edit Event
                </button>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
