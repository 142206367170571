const url = window.location.protocol + "//" + window.location.host;
const host = window.location.host;
const wsProtocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:';

export const environment = {
  gaCode: 'GOOGLE_ANALYTICS_TOKEN_TO_BE_INSERTED_BY_GRADLE',
  production: false,
  authorization: true,
  api: url,
  host: host,
  ws: wsProtocol + "//" + host + '/websocket',
  retraderBackend: "9000"
};