<div *ngIf="updateCopy" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Selected Game: {{gameToBeCopiedTitle}}</h2>
        </div>
        <div class="modal-body">
          <form #updateForm="ngForm" (ngSubmit)="createCopy(updateForm)">
            <div class="form-group">
              <label>Category ID: </label>
              <select
                name="category"
                id="categoryCreateSelection"
                class="custom-select"
                [(ngModel)]="createEventDetails.category_id"
              >
                <option *ngFor="let cat of categories" value="{{ cat.id }}">
                  {{ cat.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>Position in Category:</label>
              <input
                [ngClass]="{ 'is-invalid': orderId.errors && orderId.touched }"
                #orderId="ngModel"
                required
                name="orderID"
                min="1"
                class="form-control"
                type="number"
                appForbiddenName="-"
                [(ngModel)]="createEventDetails.order_id"
              />
              <div
                [hidden]="!orderId.errors?.required"
                class="invalid-feedback"
              >
                In-App position is required.
              </div>
              <div [hidden]="!orderId.errors?.min" class="invalid-feedback">
                In-App position must be greater than 0.
              </div>
              <div
                [hidden]="!orderId.errors?.forbiddenName"
                class="invalid-feedback"
              >
                In-App position cannot be negative.
              </div>
            </div>
            <div class="form-group">
              <label for="prefix">
                Event Prefix:
              </label>
              <input
                [ngClass]="{'is-invalid': prefix.errors && (prefix.dirty || prefix.touched)}"
                #prefix="ngModel"
                maxLength="20"
                name="prefix"
                class="form-control"
                type="text"
                [(ngModel)]="createEventDetails.prefix">

              <div [hidden]="!prefix.errors?.maxLength" class="invalid-feedback">
                Event prefix must be max 20 characters long.
              </div>
            </div>
            <div class="form-group">
              <label for="suffix">
                Event Suffix:
              </label>
              <input
                [ngClass]="{'is-invalid': suffix.errors && (suffix.dirty || suffix.touched)}"
                #suffix="ngModel"
                maxLength="20"
                name="suffix"
                class="form-control"
                type="text"
                [(ngModel)]="createEventDetails.suffix">
              <div [hidden]="!suffix.errors?.maxLength" class="invalid-feedback">
                Event suffix must be max 20 characters long.
              </div>
            </div>
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input value="Create Event" type="submit" class="btn btn-success">
                </li>
                <li>
                  <button type="button" class="btn btn-danger" (click)="closeCopyGame()">Close</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isViewingGame" class="cover">
  <app-game
    [action]="'VIEW'"
    [selectedGame]="selectedGame"
    [close]="closeViewingGame"
    [categories]="categories"
  >
  </app-game>
</div>

<div *ngIf="isEditingGame" class="cover">
  <app-game
    [action]="'EDIT'"
    [selectedGame]="selectedGame"
    [close]="closeEditingGame"
    [categories]="categories"
  >
  </app-game>
</div>

<div *ngIf="isCreatingGame" class="cover">
  <app-game
    [action]="'CREATE'"
    [close]="closeCreatingGame"
    [categories]="categories"
  >
  </app-game>
</div>

<div *ngIf="isDuplicatingGame" class="cover">
  <app-game
    [action]="'DUPLICATE'"
    [selectedGame]="selectedGame"
    [close]="closeDuplicatingGame"
    [categories]="categories"
  >
  </app-game>
</div>

<div class="container" style="margin-top: 70px;">
  <div class="row">
    <div class="col-md-auto">
      <h1>Games Page</h1>
    </div>
    <div class="col">
      <input type="text" class="form-control"[(ngModel)]="searchText" placeholder="Search..." (change)="returnSearch()">
      <button (click)="assignSubmitOpen()" class="btn btn-primary float-right" style="margin-top: 5px">
        Submit
      </button>
    </div>
    <div class="col">
      <button (click)="openCreatingGame()" class="btn btn-primary float-right">
        Create New Game
      </button>
    </div>
  </div>

  <div *ngIf="updateSubmitOpen">
  <div class="row mt-4">
    <div *ngFor="let game of pageOfItems"
         style="border-bottom: 5px solid rgb(255, 255, 255); border-left: 5px solid rgb(255, 255, 255); border-right: 5px solid rgb(255, 255, 255); border-top: none;"
         class="col-6 mb-4">
      <div class="card" style="border: 1px solid #adabab; background-color: #414141; color: white;">
        <img class="card-img-top" src="https://{{cloudfrontOpenURL}}/games/{{game.image}}"
             alt="No Image Found">
        <div style="padding:30px">
          <h4>
            {{game.title}}
          </h4>
          <p><strong>Date Added: </strong> {{game.date_added | date: 'dd/MM/yyyy'}}</p>
          <div class="container">
            <button (click)="openCopyingGame(game)" style="float:right; margin-left: 10px;"
                    class="btn btn-primary btn-sm">Create Event
            </button>
            <button (click)="openEditingGame(game)" style="float:right; margin-left: 10px;"
                    class="btn btn-primary btn-sm">Edit Game
            </button>
            <button (click)="openDuplicatingGame(game)" style="float:right; margin-left: 10px;"
                    class="btn btn-primary btn-sm">Duplicate Game
            </button>
            <button (click)="openViewingGame(game)" style="float:right; margin-left: 10px;"
                    class="btn btn-primary btn-sm">View Game
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="card-footer">
      <ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-center">
        <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item first-item">
          <a (click)="firstPage()" class="page-link" role="clickable">First</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item">
          <a (click)="previousPage()" class="page-link" role="clickable">Previous</a>
        </li>
        <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}" class="page-item number-item">
          <a (click)="selectedPage(page)" class="page-link" role="clickable">{{page}}</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item next-item">
          <a (click)="nextPage()" class="page-link" role="clickable">Next</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item last-item">
          <a (click)="lastPage()" class="page-link" role="clickable">Last</a>
        </li>
      </ul>
    </div>
</div>
</div>
