import {Component, OnInit} from '@angular/core';
import {ApiService} from '.././services/api.service';
import {Category} from "../models/Category";
import {Router} from "@angular/router";
import NotificationService from '../services/notification.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Groups} from "../models/Groups";

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {
  public groupsUrl: string = '/api/admin/v1/all/groups';
  public groups: any = [];
  public newGroup: { name: string } = {name: ""};
  public updateGroupEdit = false;
  public updateGroupCreate = false;
  public updateGroupDelete = false;
  public updateGroupTitle: string;
  public updateGroupDeleteTitle: string;
  public editGroup: any = [];
  public deleteGroup: any = [];
  public groupEditForm;
  public groupCreateForm;

  constructor(
    private api: ApiService,
    private router: Router,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.getGroups();
    this.groupEditForm = new FormGroup({
      'name': new FormControl(this.editGroup.name, [
        Validators.required,
        Validators.minLength(2)
      ])
    });
    this.groupCreateForm = new FormGroup({
      'name': new FormControl(this.newGroup.name, [
        Validators.required,
        Validators.minLength(2)
      ])
    });
  }

  getGroups() {
    return this.api.getAllGroups(this.groupsUrl).subscribe(async res => {
      this.groups = res;
    })
  }

  createNewGroup({value, valid}: { value: Groups, valid: boolean }) {
    if (!valid) {
      this.notificationService.notifyError('Please fill out the form correctly');
    } else {
      return this.api.newGroup(this.newGroup).subscribe(async (res) => {
        if (res) {
          this.updateGroupCreate = false;
          this.getGroups();
        }
      });
    }
  };

  openEditGroup(grp) {
    this.updateGroupTitle = grp.name;
    this.editGroup = grp;
    this.getGroups();
    this.updateGroupEdit = true;
  }

  openCreateGroup() {
    this.newGroup.name = "";
    this.updateGroupCreate = true;
  }

  closeCreateGroup() {
    this.updateGroupCreate = false;
  }

  closeGroup() {
    this.updateGroupEdit = false;
  }

  updateGroup({value, valid}: { value: Category, valid: boolean }) {
    if (!valid) {
      this.notificationService.notifyError('Please fill out the form correctly');
    } else {
      return this.api.editGroup(this.editGroup).subscribe(async (res) => {
        if (res) {
          this.updateGroupEdit = false;
          this.getGroups();
        }
      })
    }
  };

  openDeleteConfirm(grp) {
    this.updateGroupDeleteTitle = grp.name;
    this.deleteGroup = grp;
    this.getGroups();
    this.updateGroupDelete = true;
  }

  closeDeleteConfirm() {
    this.updateGroupDelete = false;
  }

  removeGroupUsersCleared(response) {
    return this.api.deleteGroup(this.deleteGroup).subscribe(async (res) => {
      if (res) {
        this.updateGroupDelete = false;
        this.getGroups();
      }
    })
  }

  removeGroup({value, valid}: { value: Groups, valid: boolean }) {
    return this.api.deleteGroupUsers(this.deleteGroup).subscribe(async (res) => {
      if (res) {
        await this.removeGroupUsersCleared(res);
      }
    })
  }
}
