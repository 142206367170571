<div class="container d-flex justify-content-center" style="margin-top: 70px;">
    <div class="card text-center">
      <div class="card-header">
        <div class="logo">
          <img
            src="https://{{cloudfrontOpenURL}}/categories/Amplify/amplifyme-logo.png"
            alt="Logo"
            class="amplify-logo">
        </div>
        <h5 class="card-title">ReTrader Admin Panel</h5>
      </div>
      <div class="card-body">
        <form>
          <div class="form-group">
            <label for="InputUsername">Username</label>
            <input type="text" class="form-control" [(ngModel)]="usernameInput" (change)="getUsername()" [ngModelOptions]="{standalone: true}" id="InputUsername" aria-describedby="emailHelp" placeholder="Username">
          </div>
          <div class="form-group">
            <label for="InputPassword">Password</label>
            <input type="password" class="form-control" [(ngModel)]="passwordInput" (change)="getPassword()" [ngModelOptions]="{standalone: true}" id="InputPassword" placeholder="Password">
          </div>
          <!--        <button type="submit" class="btn btn-primary">Submit</button>-->
          <button type="buton" class="btn btn-primary" style="margin-bottom: 20px"
                  (click)="loginClicked()">
            Login
          </button>
        </form>
      </div>
      <div class="card-footer text-muted">
        {{ buildVersion }}
      </div>
    </div>
</div>
