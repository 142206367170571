import {Component, OnInit} from '@angular/core';
import {ApiService} from '../services/api.service';
import {Router} from "@angular/router";
import NotificationService from '../services/notification.service';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as _ from 'lodash';
@Component({
  selector: 'app-bundles',
  templateUrl: './bundles.component.html',
  styleUrls: ['./bundles.component.css']
})

export class BundlesComponent implements OnInit {
  public bundlesUrl: string = '/api/admin/v1/all/bundles';
  public allGamesUrl: string = '/api/admin/v1/all/templateGames';
  public visibleCategoriesUrl: string = '/api/admin/v1/all/categories';
  public bundles: any = [];
  public games: any = [];
  public categories: any = [];
  public newBundle: { name: string, games: Array<{ id: number }> } = {name: "", games: []};
  public schedulingBundle: { name: string, category_id: number, startDatetime, endDatetime, games: Array<{ id: number, title: string }> } = {name: "", category_id: -1, startDatetime: null, endDatetime: null, games: []};
  public action = "";
  public groupForm;
  public eventInfo: { title: string } = { title: "" };
  public SchedulingForm;
  public createEventDetails = {prefix: "", suffix: ""}

  public ACTIONS = {
    CREATE_EVENTS: 'CREATE_EVENTS',
    CREATE_BUNDLE: 'CREATE_BUNDLE',
    EDIT_BUNDLE: 'EDIT',
    DELETE_BUNDLE: 'DELETE'
  }

  constructor(
    private api: ApiService,
    private notificationService: NotificationService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getBundles();
    this.getGames();
    this.getAllCategories();
    this.SchedulingForm = new FormGroup({
      startDateTime: new FormControl(this.schedulingBundle.startDatetime, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(39),
      ]),
      endDateTime: new FormControl(this.schedulingBundle.endDatetime, [
        Validators.required,
        Validators.min(1),
      ])
    });
  }

  getGames() {
    return this.api.getAllGames(this.allGamesUrl).subscribe(async res => {
      this.games = res;
    })
  }

  getAllCategories() {
    return this.api.getAllCategories(this.visibleCategoriesUrl).subscribe(async res => {
      this.categories = res;
    });
  }

  getBundles() {
    return this.api.getAllBundles(this.bundlesUrl).subscribe(async res => {
      this.bundles = res;
    })
  }

  createNewBundle({value, valid}: { value, valid: boolean }) {
    if (!valid) {
      this.notificationService.notifyError('Please fill out the form correctly');
    } else {
      let newBundle = {
        ...this.newBundle,
        games: this.newBundle.games.map(g => g.id)
      }
      newBundle.games = newBundle.games.filter(function(elem, index, self) {
        return index === self.indexOf(elem);
      })
      return this.api.newBundle(newBundle).subscribe(async (res: { error }) => {
        if (res.error) {
          this.notificationService.notifyError(res.error.message);
        } else {
          this.getBundles();
          this.newBundle = {name: "", games: []};
          this.closeModal();
          this.notificationService.notifySuccess("Bundle successfully created");
        }
      });
    }
  };

  openCreateEvents(bundle) {
    this.schedulingBundle = _.cloneDeep(bundle);
    this.schedulingBundle.category_id = -1;
    this.schedulingBundle.startDatetime = "";
    this.schedulingBundle.endDatetime = "";
    this.action = this.ACTIONS.CREATE_EVENTS;
  }

  openCreateBundle() {
    this.action = this.ACTIONS.CREATE_BUNDLE;
  }

  openEditBundle(bundle) {
    this.action = this.ACTIONS.EDIT_BUNDLE;
    this.newBundle = JSON.parse(JSON.stringify(bundle));
  }

  openDeleteConfirm(bundle) {
    this.action = this.ACTIONS.DELETE_BUNDLE;
    this.newBundle = JSON.parse(JSON.stringify(bundle));
  }

  closeModal() {
    this.action = "";
    this.newBundle = {name: "", games: []};
  }

  isCreatingEvents() {
    return this.action === this.ACTIONS.CREATE_EVENTS;
  }

  isCreatingBundle() {
    return this.action === this.ACTIONS.CREATE_BUNDLE;
  }

  isEditingBundle() {
    return this.action === this.ACTIONS.EDIT_BUNDLE;
  }

  isDeletingBundle() {
    return this.action === this.ACTIONS.DELETE_BUNDLE;
  }

  addGame() {
    this.newBundle.games.push({ id: this.games[0].id });
  }

  removeGame(index: number) {
    this.newBundle.games.splice(index,1);
  }

  editBundle({value, valid}) {
    if (!valid) {
      this.notificationService.notifyError('Please fill out the form correctly');
    } else {
      let newBundle = {
        ...this.newBundle,
        games: this.newBundle.games.map(g => g.id)
      }
      newBundle.games = newBundle.games.filter(function(elem, index, self) {
        return index === self.indexOf(elem);
      })
      return this.api.editBundle(newBundle).subscribe(async (res: { error }) => {
        if (res.error) {
          this.notificationService.notifyError(res.error.message);
        } else {
          this.getBundles();
          this.closeModal();
          this.notificationService.notifySuccess("Bundle successfully updated");
        }
      })
    }
  };

  deleteBundle() {
    return this.api.deleteBundle(this.newBundle).subscribe(async (res) => {
      if (res) {
        this.getBundles();
        this.closeModal();
      }
    })
  }

  createEvents({value, valid}) {
    this.schedulingBundle.startDatetime = new Date(this.schedulingBundle.startDatetime).toISOString();
    this.schedulingBundle.endDatetime = new Date(this.schedulingBundle.endDatetime).toISOString();
    if (!valid) {
      this.notificationService.notifyError('Please fill out the form correctly');
    } else if (this.createEventDetails.prefix == '' && this.createEventDetails.suffix == '') {
      this.notificationService.notifyError("Please include either a Prefix or Suffix")
      return;
    }
    else {
      this.api.scheduleCategory({ id: this.schedulingBundle.category_id, startDatetime: this.schedulingBundle.startDatetime, endDatetime: this.schedulingBundle.endDatetime }).subscribe();
      for (let game of this.schedulingBundle.games) {
        let eventGame = this.games.filter(g => g.id === game.id)[0];
        let category = this.categories.filter(c => `${c.id}` === `${this.schedulingBundle.category_id}`)[0];
        eventGame.title = [this.createEventDetails.prefix,game.title,this.createEventDetails.suffix].join(' ')
        eventGame.category_id = this.schedulingBundle.category_id;
        this.api.createEventFromBundle(eventGame).subscribe(async (res: { id, error }) => {
          if (res.error) {
            this.notificationService.notifyError(res.error.message);
          } else {
            this.api.scheduleEvent({ id: res.id, startDatetime: this.schedulingBundle.startDatetime, endDatetime: this.schedulingBundle.endDatetime }).subscribe();
            this.notificationService.notifySuccess("Events successfully created");
          }
        })
      }
      this.closeModal();
    }
  }
}
