<div *ngIf="updateAssignGroup" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Assign group for user: {{userAssignGroupTitle}}</h2>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">First Name:</label>
              <div class="col-sm-9">
                <input
                  #firstName="ngModel"
                  required
                  minLength="1"
                  maxLength="40"
                  name="first_name"
                  class="form-control"
                  type="text"
                  [(ngModel)]="editUser.first_name">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Last Name:</label>
              <div class="col-sm-9">
                <input
                  #lastName="ngModel"
                  required
                  minLength="1"
                  maxLength="40"
                  name="last_name"
                  class="form-control"
                  type="text"
                  [(ngModel)]="editUser.last_name">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Group: </label>
              <div class="col-sm-9">
                <div class="form-group">
                  <select name="groups" id="groupSelection" class="custom-select" [(ngModel)]="editUser.group_id" >
                    <option [ngValue]="null">no group</option>
                    <option *ngFor="let grp of groups" value="{{grp.id}}">
                      {{grp.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <select name="groups" id="groupSelection" class="custom-select" [(ngModel)]="chosenGroupID" >
                <option value="null"></option>
                <option *ngFor="let grp of groups" value="{{grp.id}}">
                  {{grp.name}}
                </option>
              </select>
            </div>
          </form>
          <div class="formgroup">
            <ul class="form-buttons">
              <li>
                <input value="Update" type="submit" class="btn btn-success" (click)=assignGroup() >
              </li>
              <li>
                <button class="btn btn-danger" (click)="assignGroupClose()">Close</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" style="margin-top: 70px;">
  <div class="row">
    <div class="col">
      <h1> Users Page</h1>
    </div>
    <div class="col-md-auto">
      <div class="form-group row">
        <div class="col-sm-10 col-form-label">
          <select class="custom-select" id="searchParam" (change)="changeParam()" [(ngModel)]="paramSet">
          <option value="" disabled>Choose search filter</option>
          <option *ngFor="let param of params" [value]="param.value">
            {{param.label}}
          </option>
        </select>
        </div>
        <div class="col-sm-10">
          <input type="text" class="form-control"[(ngModel)]="searchText" placeholder="Search..." (change)="returnSearch()">
          <button (click)="assignSubmitOpen()" [disabled]="paramSet === ''" class="btn btn-primary float-right" style="margin-top: 5px">
            Submit
          </button>
        </div>
    </div>
  </div>
</div>

<div *ngIf="updateSubmitOpen">
  <div class="container" style="margin-top: 20px;">
    <div class="col-md-12">
    </div>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">First</th>
        <th scope="col">Last</th>
        <th scope="col">Login</th>
        <th scope="col">School</th>
        <th width="12%" scope="col">Group</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let usr of pageOfItems">
        <tr>
          <td>{{usr.id}}</td>
          <td>{{usr.first_name}}</td>
          <td>{{usr.last_name}}</td>
          <td>{{usr.login}}</td>
          <td>{{usr.title}}</td>
          <td>{{usr.name}}</td>
          <td>
            <button (click)="assignGroupOpen(usr)" style="float:right; margin-left: 10px;"
                    class="btn btn-primary btn-sm">Assign group
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-center">
      <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item first-item">
        <a (click)="firstPage()" class="page-link" role="clickable">First</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item">
        <a (click)="previousPage()" class="page-link" role="clickable">Previous</a>
      </li>
      <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}" class="page-item number-item">
        <a (click)="selectedPage(page)" class="page-link" role="clickable">{{page}}</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item next-item">
        <a (click)="nextPage()" class="page-link" role="clickable">Next</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item last-item">
        <a (click)="lastPage()" class="page-link" role="clickable">Last</a>
      </li>
    </ul>
  </div>
</div>



