import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../services/auth.service";
import NotificationService from '../services/notification.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  usernameInput: string;
  passwordInput: string;
  public buildVersion: string;
  public paramsToSend: any = {username : "", password : ""};
  public cloudfrontOpenURL: string = "";

  constructor(
    private api: ApiService,
    private loginCheck: AuthService,
    public router: Router,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.getBuildVersion();
    this.getCloudFrontUrl();
  }

  getUsername() {
    return this.usernameInput;
  }

  getPassword() {
    return this.passwordInput;
  }

   async loginClicked() {
     this.passwordInput = this.getPassword();
     this.usernameInput = this.getUsername();
     this.paramsToSend.username = this.usernameInput;
     this.paramsToSend.password = this.passwordInput;
     await this.loginCheck.loginService(this.paramsToSend).then((res) =>{
       this.errorMessage()
     }).catch((res) =>{})
   }

   errorMessage(){
     if (!this.loginCheck.isAuthenticated()){
       this.notificationService.notifySuccess('Username or Password is incorrect, please try again.');
     };
   }

   getBuildVersion() {
    return this.api.getBuildVersion().subscribe(async (res) => {
      // @ts-ignore
      this.buildVersion = res.build;
    })
  }

  getCloudFrontUrl() {
    return this.api.getCloudfrontOpenURL().subscribe(async (res) => {
      // @ts-ignore
      this.cloudfrontOpenURL = res.URL;
    });
  }
}
