import {Component, OnInit} from '@angular/core';
import {ApiService} from '../services/api.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FlashMessagesService} from 'angular2-flash-messages';
import {Clone} from "../models/Clone";
import {Router} from "@angular/router";
import {GameGenerate} from "../models/GameGenerate";
import NotificationService from '../services/notification.service';
import moment from "moment-timezone";
import {TZone} from "moment-timezone-picker";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-game-generator',
  templateUrl: './game-generator.component.html',
  styleUrls: ['./game-generator.component.css']
})
export class GameGeneratorComponent implements OnInit {
  jun = moment();
  public gameGenerateForm;
  public newGame: GameGenerate = {
    ticker: '',
    dateData: new Date(),
    candleStickDuration: 0,
    roundingMode: 0,
    FPS: 0,
    maxPosition: 0,
    scaling: 0,
    file: File,
    newTimezone: ''
  };
  public newSeries: any = [];
  public creating = false;
  public uploadedFile;
  public uploadForm: FormGroup;
  public finalData: any;
  public downloadAvailable = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private api: ApiService,
    private router: Router,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.gameGenerateForm = new FormGroup({
      'ticker': new FormControl(this.newGame.ticker, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(20)
      ]),
      'CandleStickDuration': new FormControl(this.newGame.candleStickDuration, [
        Validators.required,
        Validators.min(1)
      ]),
      'roundingMode': new FormControl(this.newGame.roundingMode, [
        Validators.required,
        Validators.min(1)
      ]),
      'FPS': new FormControl(this.newGame.FPS, [
        Validators.required,
        Validators.min(1)
      ]),
      'maxPosition': new FormControl(this.newGame.maxPosition, [
        Validators.required,
        Validators.min(1)
      ]),
      'scaling': new FormControl(this.newGame.scaling, [
        Validators.required,
        Validators.min(0)
      ]),
      'file': new FormControl(this.uploadedFile, [
        Validators.required
      ])
    })
    this.uploadForm = this.formBuilder.group({
      gameData: ['']
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  addNewSeries() {
    this.newSeries.push({})
  }

  deleteNewSeries() {
    this.newSeries.pop()
  }

  uploadFile({value, valid}: { value: Clone, valid: boolean }) {
    const formData = new FormData();
    formData.append('prices', this.uploadForm.get('gameData').value);
    let paramsToSend = {
      ticker: this.newGame.ticker,
      fps: this.newGame.FPS,
      maxPosition: this.newGame.maxPosition,
      priceSeries: this.newSeries,
      candleStickDuration: this.newGame.candleStickDuration,
      digitsAfterDot: this.newGame.roundingMode,
      scalingFactor: this.newGame.scaling
    };
    this.creating = true;
    if (!valid) {
      this.notificationService.notifyError("Please fill out form");
      this.creating = false;
      return;
    }
    this.subscriptions.push(this.api.uploadGameDataFile(formData).subscribe((res) => {
      this.uploadGameLaunchFile(paramsToSend);
    }, error => {
      this.notificationService.notifyError("Please fill out form");
      this.creating = false;
      return;
    }));
  }

  uploadGameLaunchFile(paramsToSend) {
    this.subscriptions.push(this.api.uploadGameLaunchFile(paramsToSend).subscribe((res) => {
      this.convertTimeFrame(res)
      this.creating = false;
    }, error => {
      this.notificationService.notifyError("Please fill out form");
      this.creating = false;
      return;
    }));
  }

  convertTimeFrame(res) {
    let candleData = {"gameFile": res.gameObjectTO, "newTimezone": this.newGame.newTimezone};
    this.subscriptions.push(this.api.uploadGameTimeChange(candleData).subscribe((res) => {
      this.finalData = JSON.stringify(res, undefined, 2);
      this.downloadAvailable = true;
    }, error => {
      this.notificationService.notifyError("Please fill out form");
      this.creating = false;
      return;
    }));
  }

  onFileSelect(event) {
    if (event.target.files.length <= 0) {
      return;
    }
    const files = event.target.files[0];
    this.uploadForm.get('gameData').setValue(files);
  }

  downloadFile() {
    let file = new Blob([this.finalData], {type: '.json'});
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, (this.newGame.ticker + this.newGame.dateData) + ".json");
    else { // Others
      let a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = (this.newGame.ticker + this.newGame.dateData) + ".json";
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  setTimeZone($event: TZone) {
    this.newGame.newTimezone = $event.nameValue;
  }
}
