import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DateRedirectService {
  getRedirectUrl(): string {
    const url = new URL(window.location.href);
    const domainName = url.hostname;
    const isProduction = domainName === 'retrader.amplifyme.com';
    const today = new Date();
    const month = today.getMonth() + 1; // In JavaScript, months are 0-indexed
    const day = today.getDate();

    if (!isProduction) {
      if (month === 12 && day === 5) {
        return "/liveleaderboard/CitadelNeurIPSdev1";
      } else if (month === 12 && day === 6) {
        return "/liveleaderboard/CitadelNeurIPSdev2";
      } else if (month === 12 && day === 7) {
        return "/liveleaderboard/CitadelNeurIPSdev3";
      } else if (month === 12 && day === 8) {
        return "/liveleaderboard/CitadelNeurIPSdev4";
      }
    } else {
      if (month === 12 && day === 11) {
        return "/liveleaderboard/CitadelNeurIPSDec11";
      } else if (month === 12 && day === 12) {
        return "/liveleaderboard/CitadelNeurIPSDec12";
      } else if (month === 12 && day === 13) {
        return "/liveleaderboard/CitadelNeurIPSDec13";
      } else if (month === 12 && day === 14) {
        return "/liveleaderboard/CitadelNeurIPSDec14";
      }
    }

    return "/liveleaderboard/CitadelNeurIPSDec11";
  }
}
