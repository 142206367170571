<div class="container" style="margin-top: 70px">
  <div class="col-md-12 mb-4">
    <h1>Help</h1>
  </div>
  <div class="container" style="margin-top: 15px">
    <div class="jumbotron">
      <div class="modal-body">
        <h4 class="display-5">ReTrader Admin Panel Guides</h4>
        <div class="lead">
          Below you will find information based on the ReTrader Admin Manual.
        </div>
        <hr class="my-4" />
        <div class="row mt-4">
          <div class="col-6 mb-4">
            <div
              class="card"
              style="
                border: 1px solid #adabab;
                background-color: #414141;
                color: white;
              "
            >
              <video controls class="card-img-top">
                <source
                  src="https://dtvqe35p86kk2.cloudfront.net/Help/Retrader-CreateEvent.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <div style="padding: 30px">
                <h4>Create Event</h4>
              </div>
            </div>
          </div>
          <div class="col-6 mb-4">
            <div
              class="card"
              style="
                border: 1px solid #adabab;
                background-color: #414141;
                color: white;
              "
            >
              <video controls class="card-img-top">
                <source
                  src="https://dtvqe35p86kk2.cloudfront.net/Help/Retrader-ScheduleEvent.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <div style="padding: 30px">
                <h4>Schedule Event</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6 mb-4">
            <div
              class="card"
              style="
                border: 1px solid #adabab;
                background-color: #414141;
                color: white;
              "
            >
              <video controls class="card-img-top">
                <source
                  src="https://dtvqe35p86kk2.cloudfront.net/Help/Retrader-CreateCategory.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <div style="padding: 30px">
                <h4>Create Category</h4>
              </div>
            </div>
          </div>
          <div class="col-6 mb-4">
            <div
              class="card"
              style="
                border: 1px solid #adabab;
                background-color: #414141;
                color: white;
              "
            >
              <video controls class="card-img-top">
                <source
                  src="https://dtvqe35p86kk2.cloudfront.net/Help/Retrader-ScheduleCategory.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <div style="padding: 30px">
                <h4>Schedule Category</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6 mb-4">
            <div
              class="card"
              style="
                border: 1px solid #adabab;
                background-color: #414141;
                color: white;
              "
            >
              <video controls class="card-img-top">
                <source
                  src="https://dtvqe35p86kk2.cloudfront.net/Help/Retrader-CreateBundle.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <div style="padding: 30px">
                <h4>Create Bundle</h4>
              </div>
            </div>
          </div>
          <div class="col-6 mb-4">
            <div
              class="card"
              style="
                border: 1px solid #adabab;
                background-color: #414141;
                color: white;
              "
            >
              <video controls class="card-img-top">
                <source
                  src="https://dtvqe35p86kk2.cloudfront.net/Help/Retrader-ScheduleBundle.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <div style="padding: 30px">
                <h4>Schedule Bundle</h4>
              </div>
            </div>
          </div>
        </div>
        <div id="accordion" style="padding-top: 20px">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Adding a new category
                </button>
              </h5>
            </div>
            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                <p>
                  You will need to have a name of the category and an image. The
                  image must be 600 by 475 pixels and no more than 200 KB in
                  size.
                  <br />
                  Once you have those, log into Amazon S3:
                  <br />
                  Upload all files to the S3 bucket
                </p>
                <ul class="list">
                  <li>Open http://aws.amazon.com/de/s3/</li>
                  <li>Select Log In and Account Management</li>
                  <li>Enter the details provided separately</li>
                  <li>Select S3 on the Dashboard</li>
                  <li>Click on “retraderopencontent”</li>
                  <li>Click on “categories”</li>
                  <li>
                    Create a folder which is related to the name of the
                    category, for example: “majorhistorical” for “Major
                    Historical Events”. It should not contain any spaces or
                    non-alphabetic characters. Make a note of the folder name as
                    you will need it later.
                  </li>
                  <li>
                    Click on “Upload” and drag the image into the box (make sure
                    the name of the image also only contains letters and no
                    special signs).
                  </li>
                  <li>
                    Once the image is uploaded, select it (a small blue square
                    appears on the left-hand side) and select “Actions” and
                    click on “Make Public”, click “OK” when prompted.
                  </li>
                  <li>
                    Once this is done, log into the ReTrader admin panel and
                    entering the login details provided.
                  </li>
                  <li>Now click on “Categories”</li>
                  <li>
                    Click on “Create New Category” to create a new category
                  </li>
                  <li>Enter the required details:</li>
                  <ul>
                    <li>Name: Name of the category</li>
                    <li>
                      In-App Position: the order in which it should appear on
                      the front screen (ascending ordering).
                    </li>
                    <li>
                      Image Path: here you should enter the folder name you
                      created earlier, followed by “/” and the name of the file
                      you uploaded (enter the full name of the file including
                      the extension). For example:
                      “boamlny2015/boamlcategorypic.jpg”, hence: [FOLDER
                      NAME]/[FILENAME]
                    </li>
                    <li>
                      Description: this text will appear at the top of the
                      category
                    </li>
                  </ul>
                  <li>
                    Once all details have been entered, select “Save Category”,
                    the category is now available on the app.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Requirements for New Games
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">
                <p>
                  If you are looking to add a new game, this section contains
                  the minimum information required to make a game.
                </p>
                <p>Minimum requirements:</p>
                <ul>
                  <li>Start Time &ndash; add price level at start time</li>
                  <li>End Time &ndash; add price level at end time</li>
                  <li>
                    How the dataset should be split (some periods replayed
                    faster than others)?
                  </li>
                  <li>Equivalent in game time seconds for each period.</li>
                  <li>Game Name</li>
                  <li>
                    Game picture
                    <ul>
                      <li>Game Category</li>
                    </ul>
                  </li>
                  <li>
                    Price Series
                    <ul>
                      <li>Ticker</li>
                      <li>Candlestick length (fixed across the game)</li>
                      <li>Decimal points of data to display</li>
                    </ul>
                  </li>
                  <li>
                    For each series:
                    <ul>
                      <li>
                        Start Time (add the respective price level to check the
                        data after pulling this out)
                      </li>
                      <li>
                        End Time (add the respective price level to check the
                        data)
                      </li>
                      <li>Game time in seconds to replay</li>
                    </ul>
                  </li>
                  <li>
                    Game introduction &ndash; 30-45secs
                    <ul>
                      <li>Text</li>
                      <li>Video</li>
                    </ul>
                  </li>
                  <li>
                    Breaking News &ndash; 2-15secs
                    <ul>
                      <li>Text</li>
                      <li>Audio</li>
                    </ul>
                  </li>
                  <li>
                    These files should be tagged with a particular real time
                    moment they should appear.
                  </li>
                  <li>
                    Game outroduction &ndash; 30-45secs
                    <ul>
                      <li>Text</li>
                      <li>Video</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Requesting New CQG Price Data.
                </button>
              </h5>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div class="card-body">
                <p>
                  Email to contact: dmitryn@cqg.com
                  <br />
                  In about 24hrs you will receive the data
                  <br />
                  Sample Email:
                  <br />
                </p>
                <samp>
                  Hi Dmitry,
                  <br />
                  I hope you're well, I am just emailing to get the following
                  files.
                  <br />
                  - [INSERT TICKER]: [INSERT DATE]
                  <br />
                  - [INSERT TICKER]: [INSERT DATE]
                  <br />
                  Thanks,
                  <br />
                  [INSERT NAME]
                </samp>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFour">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Generating a New Data File
                </button>
              </h5>
            </div>
            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordion"
            >
              <div class="card-body">
                <p>
                  Go to
                  <a href="http://54.76.200.123:9010/retrader"
                    >http://54.76.200.123:9010/retrader</a
                  >
                  and log in using the details provided.
                </p>
                <ul>
                  <li>
                    Input the following:
                    <ul>
                      <li>
                        Candlestick Duration: this should be 1 minute for games
                        which compress less than 1.5hrs of data and 2 for data
                        more than that. Usually we wouldn&rsquo;t go beyond 2.
                      </li>
                      <li>
                        Rounding mode (integer number of decimal points): this
                        defines to how many decimal points the prices are
                        output. Generally this should be straightforward but
                        will depend on the asset (Equity indices 2, Treasuries
                        3, FX 4 etc.)
                      </li>
                      <li>
                        FPS (frames per second, integer): this value is used to
                        calibrate the speed and number of updates of the chart
                        per second. The value should be set no higher than 10 to
                        ensure that all devices can run this. More on what this
                        does in the notice box below.
                      </li>
                      <li>
                        Add Series: click this button to add separate episodes
                        in the price data, some slower, some faster as required.
                        For each series, add the start and end time of the real
                        market move in the format YYYY-MM-DDTHH:MM:SSZ and then
                        the number of seconds in app-time this should be
                        compressed to.
                      </li>
                      <li>
                        For historical files, DON&rsquo;T click on &ldquo;Get
                        Json&rdquo;,
                      </li>
                      <li>
                        Enter the Price scale factor which is a value to
                        multiply all the price points by (the data usually has
                        no decimals, but will be whole numbers, e.g. the Dax on
                        a given day might be traded at 8503.50, this value will
                        be stored by CQG as 850350, hence you need to specify
                        0.01 as the scale factor). Generally this will always be
                        equal to .
                      </li>
                      <li>
                        Click on &ldquo;Choose File&rdquo; and select the .ts
                        file with the price data.
                      </li>
                      <li>Click Get Json From File on the right.</li>
                      <li>
                        Some text will appear at the top informing you that the
                        process is ongoing.
                      </li>
                      <li>
                        Note that this may take some time, depending on the size
                        of the file as the file needs to be uploaded onto the
                        server.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Once completed, copy the text in the field
                    &ldquo;Data&rdquo; to a blank text file and save as with a
                    file name of format: &ldquo;YYMMDD_TICKER_Data.json&rdquo;
                    where YYMMDD is the date of the data and TICKER is the
                    ticker of the asset.
                  </li>
                </ul>
                <p>General information on how the compression works:</p>
                <ol>
                  <li>
                    The number of FPS in combination with the number of seconds
                    for any given period will define how many price points will
                    be created for that period. For example: FPS of 10 with a
                    period lasting 60 seconds of in-app time will generate 10 x
                    60 = 600 price points, which will be evenly spread out
                    across the period.
                  </li>
                  <li>
                    As a result of the point above, if the price file is getting
                    too large, you need to reduce the number of FPS to get less
                    granular
                  </li>
                  <li>
                    If 60 minutes of real-time are compressed into 60 seconds of
                    app-time with an FPS of 1, then each second in app time
                    represents one minute in real-time. In a given minute, there
                    may be several hundred price points. We are currently simply
                    assuming an even distribution across that interval of all
                    and any price points received and simply take the average.
                    We thereby ignore the exact top and bottom, assuming that if
                    those are important for that particular moment then a
                    different granularity should be taken to take those into
                    account.
                  </li>
                  <li>
                    You can increase the granularity either by increasing the
                    FPS (increases the granularity for the entire price set) or
                    by increasing the number of seconds for that given interval
                    (makes the interval longer in app-time, but also more
                    granular as FPS and real-time length stay the same). For
                    example 60minutes into 100 seconds with FPS 1 (100 price
                    points), which will increase the granularity of that period
                    or 60mintes into 60 seconds with FPS 10 (600 price points),
                    but this will equally increase the granularity of all data
                    points.
                  </li>
                </ol>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingFive">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Manual Edits
                  </button>
                </h5>
              </div>
              <div
                id="collapseFive"
                class="collapse"
                aria-labelledby="headingFive"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <h3><strong>PnL calibration</strong></h3>
                  <p>
                    In order to get the correct PnL per tick, you need to change
                    the value of the &ldquo;increment&rdquo; in beginning of the
                    price file:
                  </p>
                  <p>"Constants":</p>
                  <p>"Increment": "0.01",</p>
                  <p>"roundingMode": "2",</p>
                  <p>"fps": 3,</p>
                  <p>"maxPosition" : "1000"</p>
                  <p>
                    Where tick size is the fraction of every whole point of
                    change, i.e. for the S&amp;P the tick size is 0.25, for
                    EURUSD it&rsquo;s 0.0001.
                  </p>
                  <p>
                    Change the increment to this new value and save the .json
                    price file.
                  </p>
                  <h3><strong>Maximum Positon</strong></h3>
                  <p>
                    In addition to the increment to calibrate PnL, you can
                    change the &ldquo;maxPosition&rdquo; to set the maximum
                    position for the reTrade. The value describes the maximum
                    position a user can have at any point in time during the
                    reTrade.
                  </p>
                  <p>
                    For example, if set to 1000, the user may only ever be long
                    or short 1000 units.
                  </p>
                  <h3><strong>Shift the time forwards/backwards</strong></h3>
                  <p>
                    At the moment this is non-automated, but if you know the
                    data construct of the output file, it becomes easy to
                    manipulate.
                  </p>
                  <p>The following is a complete price point:</p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "UK Time":
                    "12:45:00",
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Date":
                    "22/09/2008",
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Price Traded":
                    "116.28",
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "minPrice":
                    "115.85",
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "maxPrice":
                    "116.4",
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "OHLC": "OL",
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Scale High":
                    "116.4",
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "Scale Low":
                    "116.17",
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "ppPerSimMin":
                    "6.98",
                  </p>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "c": null,</p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "status":
                    "success",
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; "message": null
                  </p>
                  <p>
                    Note that the UK time defines the time displayed to the user
                    on the clock. Hence if you wish to simply shift the clock by
                    a whole number of hours, all you need to do is start with
                    the last price point and replace the string:
                  </p>
                  <p>"UK Time": "12</p>
                  <p>by</p>
                  <p>"UK Time": "18</p>
                  <p>Throughout the file.</p>
                  <p>
                    Now continue to replace strings as you move through the
                    hours of generated data until you reach the first price
                    point.
                  </p>
                  <p>
                    Limitations are clearly that this only works for shift in
                    whole hours, nothing more granular.You need to do this from
                    the end of the data to the beginning to ensure that your
                    shift does not cross with the beginning of the data (this is
                    impossible if real time length of the game &lt; shift).
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingSix">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Preparing and Uploading Files to S3
                </button>
              </h5>
            </div>
            <div
              id="collapseSix"
              class="collapse"
              aria-labelledby="headingSix"
              data-parent="#accordion"
            >
              <div class="card-body">
                <h3>Prepare all files</h3>
                <h4>Video files</h4>
                <p>
                  Should be in mp4 format and not larger than ~10MB each. They
                  should be named: &ldquo;YYMMDD_TICKER_Preview.mp4&rdquo; and
                  &ldquo;YYMMDD_TICKER_Review.mp4&rdquo; for the review and
                  preview respectively.
                </p>
                <h4>Breaking News Audi files</h4>
                <p>
                  Should be in mp3 format and not larger than ~1MB each. They
                  should be named: &ldquo;YYMMDD_TICKER_event##.mp4&rdquo; where
                  event## is counted up for each breaking news item in turn,
                  i.e. event01, event02 etc.
                </p>
                <h4>The price file</h4>
                <p>Should be already correctly named and ready to go.</p>
                <h4>The image</h4>
                <p>
                  The image to be displayed should be a square image with
                  resolution of no less than 250x250 pixels. The file should be
                  named: &ldquo;YYMMDD_TICKER_pic.jpg&rdquo;.
                </p>
                <h3>Upload all files to the S3 bucket</h3>
                <ul>
                  <li>
                    Open
                    <a href="http://aws.amazon.com/de/s3/"
                      >http://aws.amazon.com/de/s3/</a
                    >
                  </li>
                  <li>Select Log In and Account Management</li>
                  <li>Enter the details provided separately</li>
                  <li>Select S3 on the Dashboard</li>
                  <li>Select &ldquo;retradercontent&rdquo;</li>
                  <li>Select &ldquo;games&rdquo;</li>
                  <li>
                    Select &ldquo;Create Folder&rdquo; and name the folder
                    &ldquo;YYMMDD_TICKER&rdquo; where YYMMDD is the date of the
                    replay and Ticker the asset traded in this replay
                  </li>
                  <li>Go into this folder</li>
                  <li>Select Upload</li>
                  <li>
                    Drag and drop all the files previously prepared onto the box
                    and click &ldquo;Start Upload&rdquo;
                  </li>
                  <li>
                    Click on &ldquo;All Buckets&rdquo; to return to the main
                    menu
                  </li>
                  <li>Click on &ldquo;retraderopencontent&rdquo;</li>
                  <li>Click on &ldquo;games&rdquo;</li>
                  <li>
                    Create a folder named identically to the one you just
                    created.
                  </li>
                  <li>
                    Click on &ldquo;Upload&rdquo; and drag the image only into
                    the box.
                  </li>
                  <li>
                    Once the image is uploaded, select it (a small blue square
                    appears on the left-hand side) and select
                    &ldquo;Actions&rdquo; and click on &ldquo;Make
                    Public&rdquo;, click &ldquo;OK&rdquo; when prompted.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingSeven">
              <h5 class="mb-0">
                <button
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Creating the New Game
                </button>
              </h5>
            </div>
            <div
              id="collapseSeven"
              class="collapse"
              aria-labelledby="headingSeven"
              data-parent="#accordion"
            >
              <div class="card-body">
                <h3>Set it all up on the app</h3>
                <ul>
                  <li>
                    Go to games page on the admin paneland enter the details
                    provided separately
                  </li>
                  <li>Click on Create New Game to add a new game</li>
                  <li>
                    Fill in all the details:
                    <ul>
                      <li>Speed: 10</li>
                      <li>Title: as displayed on the app</li>
                      <li>
                        Category: Category in which the game will be displayed
                      </li>
                      <li>
                        Position in App: the smaller the number, the further at
                        the front this game will be
                      </li>
                      <li>
                        Image Path: YYMMDD_TICKER/ YYMMDD_TICKER_pic.jpg&rdquo;
                      </li>
                      <li>Briefing: briefing text</li>
                      <li>Review: review text</li>
                      <li>
                        Game Path: YYMMDD_TICKER/ YYMMDD_TICKER_Data.json&rdquo;
                      </li>
                      <li>
                        Briefing Path: YYMMDD_TICKER/
                        YYMMDD_TICKER_Preview.mp4&rdquo;
                      </li>
                      <li>
                        Review Path: YYMMDD_TICKER/
                        YYMMDD_TICKER_Review.mp4&rdquo;
                      </li>
                      <li>Visible: Makes the game visible on the app</li>
                      <li>Results Max: 100</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    Add Event: this adds events (breaking news)
                    <ul>
                      <li>
                        <label class="col-sm-2 col-form-label"
                          >Event Time:</label
                        >The time at which this should be displayed (format:
                        HH:MM:SS) and should be in line with the data uploaded.
                      </li>
                      <li>
                        <label class="col-sm-2 col-form-label"
                          >Event Voice:</label
                        >Points to the audio file, enter: &ldquo;YYMMDD_TICKER/
                        YYMMDD_TICKER_event##.mp3&rdquo;
                      </li>
                      <li>
                        <label class="col-sm-2 col-form-label"
                          >Event News:</label
                        >Text of the news story
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>Click &ldquo;Create&rdquo;</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
