import { Component, OnInit } from '@angular/core';
import { ApiService } from '.././services/api.service';
import {Category} from "../models/Category";
import NotificationService from '../services/notification.service';
import {FormControl, FormGroup, NgForm, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  public cloudfrontOpenURL: any;
  public categoriesUrl: string = '/api/admin/v1/all/categories';
  public categories: any = [];
  public updateCatEdit = false;
  public updateCreate = false;
  public categoryEditName: string;
  public categoryEdit: Category = {id: 0,order_id: 0, visible: 0, scheduled: 0, name: '', description: '', medium_image: ''};
  public newCategory: Category = {id: 0,order_id: 0, visible: 0, scheduled: 0, name: '', description: '', medium_image: ''};
  public categoryEditForm;
  public categoryCreateForm;
  public newImage: File;
  public creating: boolean = false;
  public isSchedulingCategory: boolean = false;
  public isCancellingCategory: boolean = false;
  public idToBeCancelled: number = 0;
  public visible: boolean;
  public schedulingDatetimes = { startDatetime: "", endDatetime: "" };
  public scheduleDateTimeUTC = { id: 0, startDatetime: "", endDatetime: "" };
  public fileUploaded: boolean = false;
  public categoryImages: any = []
  public isCategoryLinks: boolean = false;
  public links: any = [];
  public idForCategoryLinks: number = 0;
  public baseUrl: string = window.location.protocol + "//" + window.location.host;

  constructor(
    private api: ApiService,
    private router: Router,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.getcloudfrontOpenURL();
    this.getCategories();
    this.categoryEditForm = new FormGroup({
      'name': new FormControl(this.categoryEdit.name, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(39)
      ]),
      'order_id': new FormControl(this.categoryEdit.name, [
        Validators.required,
        Validators.min(1),
      ]),
      'image': new FormControl(this.categoryEdit.name, [
        Validators.required,
      ]),
      'description': new FormControl(this.categoryEdit.name, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(500)
      ]),
    });
    this.categoryCreateForm = new FormGroup({
      'name': new FormControl(this.newCategory.name, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(39)
      ]),
      'order_id': new FormControl(this.newCategory.name, [
        Validators.required,
        Validators.min(1),
      ]),
      'image': new FormControl(this.newCategory.name, [
        Validators.required,
      ]),
      'description': new FormControl(this.newCategory.name, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(500)
      ]),
    });
  }

  getCategories() {
    return this.api.getAllCategories(this.categoriesUrl).subscribe(async res => {
      this.categories = res;
    })
  }

  cancelScheduledCategory(id) {
    this.api.cancelScheduledCategory({ id }).subscribe(async (res) => {
      this.getCategories();
      this.closeCancelCategory();
      this.notificationService.notifySuccess("Category successfully cancelled.");
    })
  }

  openCancelCategory(id) {
    this.idToBeCancelled = id;
    this.isCancellingCategory = true;
  }

  closeCancelCategory() {
    this.idToBeCancelled = 0;
    this.isCancellingCategory = false;
  }

  convertToDateString(dateString) {
    return new Date(dateString).toString();
  }

  isCategoryScheduled(cat) {
    return cat.start_time !== null;
  }

  editCat(cat) {
    this.getImageURLs();
    this.categoryEditName = cat.name;
    this.categoryEdit.id = cat.id;
    this.categoryEdit.name = cat.name;
    this.categoryEdit.order_id = cat.order_id;
    this.categoryEdit.description = cat.description;
    this.categoryEdit.medium_image = cat.medium_image;
    this.categoryEdit.visible = cat.visible;
    this.categoryEdit.scheduled = cat.scheduled;
    this.updateCatEdit = true;
  }

  onSubmit({value, valid}: {value: Category, valid: boolean}) {
    if(!valid) {
      this.notificationService.notifyError('Please fill out the form correctly');
    } else {
      return this.api.uploadCategoryMedia(this.categoryEdit.name.replace(/\s+/g, "").trim().toLowerCase(), this.newImage).subscribe(async (res) => {
        return this.api.editCategory(this.categoryEdit).subscribe(async (res: { error}) => {
          if (res.error){
            this.notificationService.notifyError(res.error.message);
          }
          this.updateCatEdit = false;
          this.fileUploaded = false;
          this.getCategories();
        });
      })
    }
  };

  closeModal() {
    this.updateCatEdit = false;
    this.fileUploaded = false;
  }

  createCat(){
    this.getImageURLs();
    this.updateCreate = true;
  }

  closeCreate(){
    this.updateCreate = false;
    this.fileUploaded = false;
    this.resetNewCategory();
  }

  createNewCategory({value, valid}: {value: Category, valid: boolean}){
    if(!valid) {
      this.notificationService.notifyError('Please fill out the form correctly');
    } else {
      this.creating = true;
      return this.api.uploadCategoryMedia(this.newCategory.name.replace(/\s+/g, "").trim().toLowerCase(), this.newImage).subscribe(async (res) => {
        return this.api.newCategory(this.newCategory).subscribe(async(res: { error }) => {
          if (res.error) {
            this.notificationService.notifyError(res.error.message);
          }
          this.creating = false;
          this.updateCreate = false;
          this.fileUploaded = false;
          this.getCategories();
        });
      })
    }
  }

  scheduleCategory() {
    this.scheduleDateTimeUTC.startDatetime = new Date(this.schedulingDatetimes.startDatetime).toISOString();
    this.scheduleDateTimeUTC.endDatetime = new Date(this.schedulingDatetimes.endDatetime).toISOString();
    this.api.scheduleCategory(this.scheduleDateTimeUTC).subscribe(async (res: { error }) => {
      if (res.error) {
        this.notificationService.notifyError(res.error)
      } else {
        this.notificationService.notifySuccess("Scheduled successfully")
      }
      this.getCategories();
      this.closeScheduling();
    });
  }

  schedulingCategory(cat) {
    this.isSchedulingCategory = true;
    this.scheduleDateTimeUTC.id = cat.id;
  }

  closeScheduling() {
    this.isSchedulingCategory = false;
  }

  selectContent(content: HTMLInputElement) {
    const file: File = content.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.newCategory.medium_image = `${this.newCategory.name.replace(/\s+/g, "").trim().toLowerCase()}/${file.name.replace(/\s+/g, "").trim()}`;
      this.fileUploaded = true;
    });

    if (file) {
      reader.readAsDataURL(file);
      this.newImage = file;
    }
  }

  selectContentUpdate(content: HTMLInputElement) {
    const file: File = content.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.categoryEdit.medium_image = `${this.categoryEdit.name.replace(/\s+/g, "").trim().toLowerCase()}/${file.name.replace(/\s+/g, "").trim()}`;
      this.fileUploaded = true;
    });

    if (file) {
      reader.readAsDataURL(file);
      this.newImage = file;
    }
  }

  isScheduled(cat) {
    return cat.scheduled === 1;
  }

  getcloudfrontOpenURL() {
    return this.api.getCloudfrontOpenURL().subscribe(async (res) => {
      // @ts-ignore
      this.cloudfrontOpenURL = res.URL;
    })
  }

  getImageURLs() {
    return this.api.getFilesCategory().subscribe(async res => {
      this.categoryImages = res;
    })
  }

  resetNewCategory() {
    this.newCategory = {id: 0, order_id: 0, visible: 0, scheduled: 0, name: '', description: '', medium_image: ''};
  }

  // category links
  openCategoryLinks(cat) {
    this.categoryEditName = cat.name;
    this.isCategoryLinks = true;
    this.displayUrlLinks(cat);
  }

  closeCategoryLinks() {
    this.isCategoryLinks = false;
  }

  displayUrlLinks(cat) {
    this.idForCategoryLinks = cat.id;
    this.links = [
      {
        linkText: 'Open App',
        url: this.baseUrl + "/openapp?category=" + this.idForCategoryLinks
      },
      {
        linkText: 'Public Live Leaderboard (Top 10)',
        url: this.baseUrl + "/liveleaderboard?category_id=" + this.idForCategoryLinks + "&shownegative=false&top=10"
      },
      {
        linkText: 'Full Leaderboard (Including Negative PnL)',
        url: this.baseUrl + "/liveleaderboard?category_id=" + this.idForCategoryLinks + "&shownegative=true"
      }
    ];
  }

  copyLink(inputElement: HTMLInputElement, link: any){
    inputElement.select();
    document.execCommand('copy');
    inputElement.blur();

    link.buttonText = "Copied!";
    setTimeout(() => {
      link.buttonText = "Copy";
    }, 3000);
  }
}
