import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';

import { ApiService } from './services/api.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppusersComponent } from './appusers/appusers.component';
import {AuthService} from "./services/auth.service";
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { BundlesComponent } from './bundles/bundles.component';
import { CategoriesComponent } from './categories/categories.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DateRedirectService } from './services/DateRedirect.service';
import { EventsComponent } from './events/events.component';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { ForbiddenValidatorDirective } from "./validators/forbidden-name.directive";
import { GameComponent } from './games/game/game.component';
import { GameGeneratorComponent } from './game-generator/game-generator.component';
import { GamesComponent } from './games/games.component';
import { GroupsComponent } from './groups/groups.component';
import { HelpComponent } from './help/help.component';
import { HttpClientModule } from '@angular/common/http';
import { LiveleaderboardComponent } from './liveleaderboard/liveleaderboard.component';
import { LoginComponent } from './login/login.component';
import {MomentTimezonePickerModule} from "moment-timezone-picker";
import { NavbarComponent } from './navbar/navbar.component';
import { NgModule } from '@angular/core';
import NotificationService from './services/notification.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RedirectGuard } from './services/redirect-guard.service';
import {SearchPipe} from './pipes/search.pipe';
import { SettingsComponent } from './settings/settings.component';
import {TextareaAutosizeModule} from "ngx-textarea-autosize";
import { DeleteAccountComponent } from './delete-account/delete-account.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    PageNotFoundComponent,
    GamesComponent,
    GameComponent,
    CategoriesComponent,
    GroupsComponent,
    BundlesComponent,
    SettingsComponent,
    AppusersComponent,
    EventsComponent,
    SearchPipe,
    LoginComponent,
    ForbiddenValidatorDirective,
    GameGeneratorComponent,
    HelpComponent,
    LiveleaderboardComponent,
    DeleteAccountComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    FlashMessagesModule.forRoot(),
    TextareaAutosizeModule,
    AutocompleteLibModule,
    ReactiveFormsModule,
    MomentTimezonePickerModule
  ],
  providers: [ApiService, FormBuilder, AuthService, NotificationService, DateRedirectService, RedirectGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
