<div class="account">
  <div class="delete">
    <div>
      <h1 class="title">ReTrader</h1>
      <h2 class="sub-title">Delete my account</h2>
      <p class="deletion-steps">To delete you account, please follow these steps:</p>
      <ol>
        <li>Open the app on your mobile device</li>
        <li>Login using your email and password</li>
        <li>Click on the settings icon on the top bar</li>
        <li>Click on the Delete Account button</li>
        <li>Confirm your intention by typing DELETE in the popup form</li>
      </ol>
      <p>Following a successful deletion, you can rest assured that all
        <br>
        your personal information will be deleted. </p>
      <p>If you have played ReTrader games and have scores saved in <br>
        the leaderboards, those will remain on our system to maintain <br>
        the leaderboard consistency. </p>
    </div>
    <div>
      <img src="assets/deletion-logo.png">
    </div>
  </div>
  <div class="delete delete-images">
    <div>
      <img class="delete-image" src="assets/empty-delete.png">
    </div>
    <div>
      <img class="delete-image" src="assets/filled-delete.png">
    </div>
  </div>
</div>
