import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import NotificationService from '../services/notification.service';
import { Clone } from '../models/Clone';
import {Game} from "../models/Game";
import {GameEvents} from "../models/GameEvents";

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.css']
})
export class GamesComponent implements OnInit {
  @ViewChild('updateForm') from: any;
  public allGamesUrl: string = '/api/admin/v1/all/templateGames';
  public categoriesUrl: string = '/api/admin/v1/all/categories';
  public cloudfrontOpenURL: any;
  public games: any = [];
  public filteredGames: any = [];
  public updateCopy = false;
  public isViewingGame = false;
  public isCreatingGame = false;
  public isEditingGame = false;
  public isDuplicatingGame = false;
  public gameToBeCopiedTitle: string;
  public clonedGame: Clone = {id: 0, category_id: 0, order_id: 0};
  public createEventDetails = {prefix: "", suffix: "", category_id: 0, order_id: 0}
  public selectedGame: Game = { id: 0, title: '', speed: 0, category_id: 0, order_id: 0, image: '', briefing: '', review: '', data_file_cdn_object: '', briefing_cdn_object: '', review_cdn_object: '', visible: 0, max_results_to_show: 0};
  public titleForm;
  public latestGame: any = [];
  public oldAndNewGame: any = {old_id: 0, new_id: 0};
  public selectedGameEvents: any = [];
  public categories: any = [];
  public searchText = '';
  public paramsToSend: any = {dbName: "", dbColumn: "", searchText: "", page: 1};
  public updateSubmitOpen = false;
  public openContent: Array<File> = [];
  public closedContent: Array<File> = [];

  pager = {
    currentPage: undefined,
    pages: undefined,
    totalPages: undefined
  };
  pageOfItems = [];
  constructor(
    private api: ApiService,
    private router: Router,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.getCloudfrontOpenURL();
    this.getAllGames();
    this.getCategories();
    this.assignSubmitOpen();
    this.titleForm = new FormGroup({
      'title': new FormControl(this.clonedGame.title, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(39)
      ]),
    });
  }

  openCopyingGame(game) {
    this.gameToBeCopiedTitle = game.title; 
    this.clonedGame = Object.assign({}, game);
    this.getAllGames();
    this.updateCopy = true;
  }

  closeCopyGame(){
    this.updateCopy = false;
  }

  createCopy({value, valid}: {value: Clone, valid: boolean}) {
    if(!valid) {
      this.notificationService.notifyError("Please fill out the form correctly")
      return;
    }
    else if (this.createEventDetails.prefix == '' && this.createEventDetails.suffix == '') {
      this.notificationService.notifyError("Please include either a Prefix or Suffix")
      return;
    }
    this.clonedGame.title = [this.createEventDetails.prefix,this.clonedGame.title,this.createEventDetails.suffix].join(' ');
    this.clonedGame.category_id = this.createEventDetails.category_id
    this.clonedGame.order_id = this.createEventDetails.order_id
    return this.api.copyGame(this.clonedGame).subscribe(async (res) => {
      this.oldAndNewGame.old_id = this.clonedGame.id;
      if (res) {
        await this.getLastGame();
      }
    });
  };

  getLastGame() {
    return this.api.getLastGame().subscribe(async (res) => {
      this.latestGame = res;
      this.oldAndNewGame.new_id = this.latestGame[0].id;
      if (res) {
        if(this.copyNewsEvents()){
          this.updateCopy = false;
          this.getAllGames();
        }
      }
    })
  };

  copyNewsEvents() {
    return this.api.copyEvents(this.oldAndNewGame).subscribe(async (res) => {
      if (res) {
        return true;
      }
    })
  };

  // Load games
  getCategories(){
    this.api.getAllCategories(this.categoriesUrl).subscribe(async res => {
      this.categories = res;
    })
  }

  getAllGames() {
    this.api.getAllGames(this.allGamesUrl).subscribe(async res => {
      this.games = res;
    })
  }

  async assignSubmitOpen() {
    this.paramsToSend.dbName = 'template_games'
    this.paramsToSend.dbColumn = 'title';
    this.updateSubmitOpen = true;
    this.paramsToSend.page = 1;
    this.paramsToSend.searchText = this.searchText;
    await this.getFilteredGames();
  }

  getFilteredGames() {
    return this.api.getFilteredGames(this.paramsToSend).subscribe(async (res) => {
      this.filteredGames = res;
      this.pager = this.filteredGames.pager;
      this.pageOfItems = this.filteredGames.pageOfItems;
    })
  }

  getCloudfrontOpenURL() {
    return this.api.getCloudfrontOpenURL().subscribe(async (res) => {
      // @ts-ignore
      this.cloudfrontOpenURL = res.URL;
    })
  }

  // Open and close game modals
  openViewingGame(game) {
    this.selectedGame = game;
    this.isViewingGame = true;
  }

  closeViewingGame = () => {
    this.isViewingGame = false;
  }

  openEditingGame(game) {
    this.selectedGame = Object.assign({}, game);
    this.isEditingGame = true;
  }

  closeEditingGame = () => {
    this.isEditingGame = false;
    this.getFilteredGames();
  }

  openCreatingGame(){
    this.isCreatingGame = true;
  }

  closeCreatingGame = () => {
    this.isCreatingGame = false;
    this.getFilteredGames();
  }

  // Open and close game modals
  openDuplicatingGame(game) {
    this.selectedGame = Object.assign({}, game);
    this.isDuplicatingGame = true;
  }

  closeDuplicatingGame = () => {
    this.isDuplicatingGame = false;
    this.getFilteredGames();
  }

  // Widget functions
  returnSearch() {
    return this.searchText;
  }

  nextPage(){
    this.paramsToSend.page++;
    this.getFilteredGames();
  }

  previousPage(){
    if(this.paramsToSend.page >= 1){
      this.paramsToSend.page--;
    }
    this.getFilteredGames();
  }

  lastPage(){
    this.paramsToSend.page = this.pager.totalPages;
    this.getFilteredGames();
  }

  firstPage(){
    this.paramsToSend.page = 1;
    this.getFilteredGames();
  }

  selectedPage(page) {
    this.paramsToSend.page = page;
    this.getFilteredGames();
  }
};
