<div *ngIf="updateCatEdit" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Update Category: {{categoryEditName}}</h2>
        </div>
        <div class="modal-body">
          <form #updateForm="ngForm" (ngSubmit)="onSubmit(updateForm)">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Name: </label>
              <div class="col-sm-9">
              <input
                [ngClass]="{'is-invalid': name.errors && (name.dirty || name.touched)}"
                #name="ngModel"
                required
                minLength="1"
                maxLength="39"
                name="name"
                class="form-control"
                type="text"
                [(ngModel)]="categoryEdit.name">
              <div [hidden]="!name.errors?.required" class="invalid-feedback">
                Category name is required.
              </div>
              <div [hidden]="!name.errors?.minLength" class="invalid-feedback">
                Category name must be at least 1 characters long.
              </div>
              <div [hidden]="!name.errors?.maxLength" class="invalid-feedback">
                Category name must be max 40 characters long.
              </div>
            </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">In-App Position:</label>
              <div class="col-sm-9">
              <input
                [ngClass]="{'is-invalid': order_id.errors && (order_id.dirty || order_id.touched)}"
                #order_id="ngModel"
                required
                min="1"
                name="order_id"
                class="form-control"
                type="number"
                appForbiddenName="-"
                [(ngModel)]="categoryEdit.order_id">
              <div [hidden]="!order_id.errors?.required" class="invalid-feedback">
                In-App position is required.
              </div>
              <div [hidden]="!order_id.errors?.min" class="invalid-feedback">
                In-App position must be greater than 0.
              </div>
              <div [hidden]="!order_id.errors?.forbiddenName" class="invalid-feedback">
                In-App position cannot be negative.
              </div>
              </div>
            </div>
            <div class="form-group row" *ngIf="!isScheduled(categoryEdit)">
              <label class="col-sm-3 col-form-label">Visibility: </label>
              <div class="col-sm-9">
                <select
                  name="visibility"
                  id="visibilitySelection"
                  class="custom-select"
                  [(ngModel)]="categoryEdit.visible">
                  <option value="1"> Show</option>
                  <option value="0"> Hide</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Scheduled: </label>
              <div class="col-sm-9">
                <select
                  name="scheduled"
                  id="scheduledSelection"
                  class="custom-select"
                  [(ngModel)]="categoryEdit.scheduled">
                  <option value="1">Category to be scheduled</option>
                  <option value="0">Category not to be scheduled</option>>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Image Path:</label>
              <div class="col-sm-7">
                <input
                  [ngClass]="{'is-invalid': image.errors && (image.dirty || image.touched)}"
                  #image="ngModel"
                  required
                  [disabled]="categoryEdit.name === '' || fileUploaded"
                  name="image"
                  class="form-control"
                  list="datalistOptions"
                  [(ngModel)]="categoryEdit.medium_image">
                <datalist id="datalistOptions">
                  <option *ngFor="let img of categoryImages" value="{{img}}">
                </datalist>
                <div [hidden]="!image.errors?.required" class="invalid-feedback">
                  Image Path is required.
                </div>
              </div>
              <div class="col-sm-2">
                <input
                  #imageInput
                  [disabled]="categoryEdit.name === ''"
                  type="file"
                  accept="image/*"
                  class="form-control"
                  id="imageInputUpdate"
                  (change)="selectContentUpdate(imageInput)"
                  hidden>
                  <input class="btn btn-primary" type="button" value="Browse..." onclick="document.getElementById('imageInputUpdate').click();" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Description:</label>
              <div class="col-sm-9">
              <textarea
                autosize
                [ngClass]="{'is-invalid': description.errors && (description.dirty || description.touched)}"
                #description="ngModel"
                required
                minLength="1"
                maxLength="500"
                name="description"
                class="form-control"
                type="text"
                [(ngModel)]="categoryEdit.description">
              </textarea>
              <div [hidden]="!description.errors?.required" class="invalid-feedback">
                Description is required.
              </div>
              <div [hidden]="!description.errors?.minLength" class="invalid-feedback">
                Description must be at least 1 characters long.
              </div>
              <div [hidden]="!description.errors?.maxLength" class="invalid-feedback">
                Description must be max 500 characters long.
              </div>
            </div>
            </div>
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input value="Update" type="submit" class="btn btn-success">
                </li>
                <li>
                  <button type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isCategoryLinks" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Category Links: {{categoryEditName}}</h2>
        </div>
        <div class="modal-body">
          <form>
             <div *ngFor="let link of links">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">{{link.linkText}}:</label>
                <div class="col-sm-9 links-row">
                  <input
                  type="text"
                  readonly [value]="link.url" #textInput
                  class="form-control"
                  >
                  <button type="button" class="btn btn-primary" (click)="copyLink(textInput, link)" [textContent]="link.buttonText ? link.buttonText : 'Copy'"></button>
                </div>
              </div>
             </div>
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <button type="button" class="btn btn-danger" (click)="closeCategoryLinks()">Close</button>
                </li>
              </ul>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="updateCreate" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Create New Category</h2>
        </div>
        <div class="modal-body">
          <form #createForm="ngForm" (ngSubmit)="createNewCategory(createForm)">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Name:</label>
              <div class="col-sm-9">
              <input
                [ngClass]="{'is-invalid': name.errors && (name.dirty || name.touched)}"
                #name="ngModel"
                required
                minLength="1"
                maxLength="40"
                name="name"
                class="form-control"
                type="text"
                [(ngModel)]="newCategory.name">
              <div [hidden]="!name.errors?.required" class="invalid-feedback">
                Category name is required.
              </div>
              <div [hidden]="!name.errors?.minLength" class="invalid-feedback">
                Category name must be at least 1 characters long.
              </div>
              <div [hidden]="!name.errors?.maxLength" class="invalid-feedback">
                Category name must be max 40 characters long.
              </div>
            </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">In-App Position:</label>
              <div class="col-sm-9">
              <input
                [ngClass]="{'is-invalid': order_id.errors && (order_id.dirty || order_id.touched)}"
                #order_id="ngModel"
                required
                min="1"
                name="order_id"
                class="form-control"
                type="number"
                appForbiddenName="-"
                [(ngModel)]="newCategory.order_id">
              <div [hidden]="!order_id.errors?.required" class="invalid-feedback">
                In-App position is required.
              </div>
              <div [hidden]="!order_id.errors?.min" class="invalid-feedback">
                In-App position must be greater than 0.
              </div>
              <div [hidden]="!order_id.errors?.forbiddenName" class="invalid-feedback">
                In-App position cannot be negative.
              </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Visibility: </label>
              <div class="col-sm-9">
                <select
                  name="visibility"
                  id="visibilitySelection"
                  class="custom-select"
                  [(ngModel)]="newCategory.visible">
                  <option value="1"> Show</option>
                  <option value="0"> Hide</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Scheduled: </label>
              <div class="col-sm-9">
                <select
                  name="scheduled"
                  id="scheduledSelection"
                  class="custom-select"
                  [(ngModel)]="newCategory.scheduled">
                  <option value="1">Category to be scheduled</option>
                  <option value="0">Category not to be scheduled</option>>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Image Path:</label>
              <div class="col-sm-7">
                <input
                  [ngClass]="{'is-invalid': image.errors && (image.dirty || image.touched)}"
                  #image="ngModel"
                  required
                  [disabled]="newCategory.name === '' || fileUploaded"
                  name="image"
                  class="form-control"
                  list="datalistOptions"
                  [(ngModel)]="newCategory.medium_image">
                <datalist id="datalistOptions">
                  <option *ngFor="let img of categoryImages" value="{{img}}">
                </datalist>
                <div [hidden]="!image.errors?.required" class="invalid-feedback">
                  Image Path is required.
                </div>
              </div>
              <div class="col-sm-2">
                <input
                  #imageInput
                  [disabled]="newCategory.name === ''"
                  type="file"
                  accept="image/*"
                  class="form-control"
                  id="imageInput"
                  (change)="selectContent(imageInput)"
                  hidden>
                  <input class="btn btn-primary" type="button" value="Browse..." onclick="document.getElementById('imageInput').click();" />
              </div>
            </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Description:</label>
                <div class="col-sm-9">
              <textarea
                autosize
                [ngClass]="{'is-invalid': description.errors && (description.dirty || description.touched)}"
                #description="ngModel"
                required
                minLength="1"
                maxLength="500"
                name="description"
                class="form-control"
                type="text"
                [(ngModel)]="newCategory.description">
              </textarea>
              <div [hidden]="!description.errors?.required" class="invalid-feedback">
                Description is required.
              </div>
              <div [hidden]="!description.errors?.minLength" class="invalid-feedback">
                Description must be at least 1 characters long.
              </div>
              <div [hidden]="!description.errors?.maxLength" class="invalid-feedback">
                Description must be max 500 characters long.
              </div>
                </div>
            </div>
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <button class="btn btn-success" type="submit">
                    <span *ngIf="!creating">Create</span>
                    <div *ngIf="creating" class="spinner-border" style="width: 1.5rem; height: 1.5rem;">
                      <span class="sr-only"></span>
                    </div>
                  </button>
                </li>
                <li>
                  <button type="button" class="btn btn-danger"  (click)="closeCreate()">Close</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isSchedulingCategory" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Scheduling Category</h2>
        </div>
        <div class="modal-body">
          <form #SchedulingForm="ngForm" (ngSubmit)="scheduleCategory()">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Category start date/time: </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  #categoryStartDatetime="ngModel"
                  name="categorytartDatetime"
                  type="datetime-local"
                  required
                  [(ngModel)]="schedulingDatetimes.startDatetime">
                </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Category end date/time: </label>
              <div class="col-sm-9">
                <input
                class="form-control"
                #categoryEndDatetime="ngModel"
                name="categoryEndDatetime"
                type="datetime-local"
                required
                [(ngModel)]="schedulingDatetimes.endDatetime">
              </div>
            </div>
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input value="Schedule" type="submit" class="btn btn-success">
                </li>
                <li>
                  <button type="button" class="btn btn-danger" (click)="closeScheduling()">Close</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isCancellingCategory" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Confirm Remove Scheduling</h2>
        </div>
        <div class="modal-body">
          <form #updateGroupDeleteForm="ngForm" (ngSubmit)="cancelScheduledCategory(idToBeCancelled)">
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input value="Yes" type="submit" class="btn btn-success">
                </li>
                <li>
                  <button class="btn btn-danger" (click)="closeCancelCategory()">No</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" style="margin-top: 70px;">
  <div class="col-md-12 mb-4">
    <h1> Categories
      <button (click)="createCat()" style="float:right; margin-top: 10px"
              class="btn btn-primary btn-sm">Create New Category
      </button>
    </h1>
  </div>
  <div class="row mt-4">
    <div *ngFor="let cat of categories"
         style="border-bottom: 5px solid rgb(255, 255, 255); border-left: 5px solid rgb(255, 255, 255); border-right: 5px solid rgb(255, 255, 255); border-top: none;"
         class="col-6 mb-4">
      <div class="card" style="border: 1px solid #adabab; background-color: #414141; color: white;">
        <img class="card-img-top"
             src="https://{{cloudfrontOpenURL}}/categories/{{cat.medium_image}}"
             alt="No Image Found">
        <div style="padding:30px">
          <h4>{{cat.name}}</h4>
          <p><strong>Category ID: </strong>{{cat.id}}</p>
          <p><strong>In-App Position: </strong>{{cat.order_id}}</p>
          <p *ngIf="!isScheduled(cat)"><strong>Visibility:</strong> {{cat.visible === 0 ? "Hidden" : "Visible"}}</p>
          <p *ngIf="isScheduled(cat)"><strong>Scheduled:</strong> Visible during scheduled time</p>
          <div *ngIf="isCategoryScheduled(cat)">
            <p>
              <strong>Start Date/Time:</strong>
              {{ convertToDateString(cat.start_time) }}
            </p>
            <p>
              <strong>End Date/Time:</strong>
              {{ convertToDateString(cat.end_time) }}
            </p>
          </div>
          <strong>Description: </strong>
          <p>{{cat.description}}</p>
          <div class="container">
            <button (click)="editCat(cat)" style="float:right; margin-left: 10px;"
                    class="btn btn-primary btn-sm">Edit Category
            </button>
            <button
            (click)="openCancelCategory(cat.id)"
            class="btn btn-danger btn-sm category-btns"
            *ngIf="isCategoryScheduled(cat)"
            >
              Remove Scheduling
            </button>
            <button
              (click)="schedulingCategory(cat)"
              class="btn btn-success btn-sm category-btns"
              *ngIf="isScheduled(cat)"
              >
              Schedule Category
            </button>

            <button
              (click)="openCategoryLinks(cat)"
              class="btn btn-info btn-sm category-btns"
              >
              Links
            </button>
          </div>
      </div>
    </div>
  </div>
</div>
