<div *ngIf="auth.isAuthenticated()">
  <!-- Image and text -->
  <nav class="navbar fixed-top navbar-expand-sm navbar-dark bg-dark">
    <a class="navbar-brand" routerLink="/dashboard">ReTrader Admin Panel</a>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNav">
      <div class="navbar-nav mr-auto">
        <a class="nav-link" routerLink="/dashboard"
          >Home <span class="sr-only">(current)</span></a
        >
        <a class="nav-link" routerLink="/games">Games</a>
        <a class="nav-link" routerLink="/events">Events</a>
        <a class="nav-link" routerLink="/categories">Categories</a>
        <a class="nav-link" routerLink="/bundles">Bundles</a>
        <a class="nav-link" routerLink="/groups">Groups</a>
        <a class="nav-link" routerLink="/games/generate">Game Generator</a>
        <a class="nav-link" routerLink="/appusers">Users</a>
      </div>
      <div class="navbar-nav">
        <a class="nav-link" routerLink="/help">Help</a>
        <!--      <a class="nav-link" routerLink="/settings">Settings</a>-->
        <button
          class="btn btn-sm btn-outline-danger"
          type="button"
          (click)="this.auth.logout()"
        >
          Log Out
        </button>
      </div>
    </div>
  </nav>
</div>
