import { CanActivate, Router } from "@angular/router";

import { DateRedirectService } from "./DateRedirect.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RedirectGuard implements CanActivate {
  constructor(
    private dateRedirectService: DateRedirectService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const redirectUrl = this.dateRedirectService.getRedirectUrl();
    this.router.navigate([redirectUrl]);
    return false; // Prevent navigation to the current route
  }
}
