<!-- CREATING EVENTS FROM BUNDLE-->

<div *ngIf="isCreatingEvents()" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Selected Bundle: {{ schedulingBundle.name }}</h2>
        </div>
        <div class="modal-body">
          <form #SchedulingForm="ngForm" (ngSubmit)="createEvents(SchedulingForm)">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Category: </label>
              <div class="col-sm-9">
                <select [(ngModel)]="schedulingBundle.category_id" name="category" class="custom-select">
                  <option *ngFor="let option of categories" [value]="option.id">
                    {{option.name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Bundle start date/time:
              </label>
              <div class="col-sm-9">
                <input
                  #bundleStartDatetime="ngModel"
                  name="bundleStartDatetime"
                  type="datetime-local"
                  required
                  [(ngModel)]="schedulingBundle.startDatetime"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Bundle end date/time:
              </label>
              <div class="col-sm-9">
                <input
                  #bundleEndDatetime="ngModel"
                  name="bundleEndDatetime"
                  type="datetime-local"
                  required
                  [(ngModel)]="schedulingBundle.endDatetime"
                />
              </div>
            </div>
            <hr>
            <div class="form-group">
              <label for="prefix">
                Event Prefix:
              </label>
              <input
                [ngClass]="{'is-invalid': prefix.errors && (prefix.dirty || prefix.touched)}"
                #prefix="ngModel"
                maxLength="20"
                name="prefix"
                class="form-control"
                type="text"
                [(ngModel)]="createEventDetails.prefix">

              <div [hidden]="!prefix.errors?.maxLength" class="invalid-feedback">
                Event prefix must be max 20 characters long.
              </div>
            </div>
            <div class="form-group">
              <label for="prefix">
                Event Suffix:
              </label>
              <input
                [ngClass]="{'is-invalid': suffix.errors && (suffix.dirty || suffix.touched)}"
                #suffix="ngModel"
                maxLength="20"
                name="suffix"
                class="form-control"
                type="text"
                [(ngModel)]="createEventDetails.suffix">
              <div [hidden]="!suffix.errors?.maxLength" class="invalid-feedback">
                Event suffix must be max 20 characters long.
              </div>
            </div>
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input value="Create Event" type="submit" class="btn btn-success">
                </li>
                <li>
                  <button type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- CREATING/EDITING BUNDLE -->

<div *ngIf="isCreatingBundle() || isEditingBundle()" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 *ngIf="isCreatingBundle()" class="modal-title">Create Bundle</h2>
          <h2 *ngIf="isEditingBundle()" class="modal-title">Edit Bundle</h2>
        </div>
        <div class="modal-body">
          <form #bundleForm="ngForm">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Bundle Name: </label>
              <div class="col-sm-9">
                <input
                  [ngClass]="{'is-invalid': name.errors &&  (name.dirty || name.touched)}"
                  #name="ngModel"
                  required
                  minlength="2"
                  name="name"
                  class="form-control"
                  type="text"
                  [(ngModel)]="newBundle.name">
                <div [hidden]="!name.errors?.required" class="invalid-feedback">
                  Bundle name is required.
                </div>
                <div [hidden]="!name.errors?. minlength" class="invalid-feedback">
                  Bundle name must be at least 4 characters long.
                </div>
              </div>
            </div>
            <ng-container *ngIf="isCreatingBundle()">
              <div *ngFor="let game of newBundle.games; let i = index" [attr.data-index]="i">
                <hr>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Game {{ i + 1 }}: </label>
                  <div class="col-sm-8">
                    <select [(ngModel)]="game.id" name="game-{{i}}" class="custom-select">
                      <option *ngFor="let option of games" [value]="option.id">
                        {{option.title}}
                      </option>
                    </select>
                  </div>
                  <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="removeGame(i)"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="isEditingBundle()">
              <div *ngFor="let game of newBundle.games; let i = index" [attr.data-index]="i">
                <hr>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Game {{ i + 1 }}: </label>
                  <div class="col-sm-8">
                      <select [(ngModel)]="game.id" name="game-{{i}}" class="custom-select">
                        <option *ngFor="let option of games" [value]="option.id">
                          {{option.title}}
                        </option>
                      </select>
                    </div>
                    <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="removeGame(i)"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
              </div>
            </ng-container>
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <button *ngIf="isCreatingBundle()" type="submit" class="btn btn-success" (click)="createNewBundle(bundleForm)">Create</button>
                  <button *ngIf="isEditingBundle()" type="submit" class="btn btn-success" (click)="editBundle(bundleForm)">Update</button>
                </li>
                <li>
                  <button *ngIf="isCreatingBundle()" type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
                  <button *ngIf="isEditingBundle()" type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
                </li>
                <li>
                  <button type="button" class="btn btn-primary float-right" (click)="addGame()">Add game</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- DELETING BUNDLE -->

<div *ngIf="isDeletingBundle()" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Confirm Delete Bundle: {{ newBundle.name }}</h2>
        </div>
        <div class="modal-body">
          <form #updateGroupDeleteForm="ngForm" (ngSubmit)="deleteBundle()">
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input value="Yes" type="submit" class="btn btn-success">
                </li>
                <li>
                  <button type="button" class="btn btn-danger" (click)="closeModal()">No</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" style="margin-top: 70px;">
  <div class="col-md-12 mb-4">
    <h1> Bundles
      <button (click)="openCreateBundle()" style="float:right; margin-top: 10px"
              class="btn btn-primary btn-sm">Create New Bundle
      </button>
    </h1>
  </div>
  <div class="row mt-4">
    <div *ngFor="let bundle of bundles"
         style="border-bottom: 5px solid rgb(255, 255, 255); border-left: 5px solid rgb(255, 255, 255); border-right: 5px solid rgb(255, 255, 255); border-top: none;"
         class="col-6 mb-4">
      <div class="card" style="border: 1px solid #adabab; background-color: #414141; color: white;">
        <div style="padding:30px">
          <h4>
            {{bundle.name}}
          </h4>
          <p><strong>Date Bundle Created:</strong> {{bundle.date_added | date: 'dd/MM/yyyy'}}</p>
          <p><strong>Games in Bundle:</strong>
            <ul>
              <li *ngFor="let game of bundle.games;">{{ game.title }}</li>
            </ul>
          <div class="container">
            <button (click)="openDeleteConfirm(bundle)" style="float:right; margin-left: 10px;"
                    class="btn btn-danger btn-sm">Delete Bundle
            </button>
            <button (click)="openEditBundle(bundle)" style="float:right; margin-left: 10px;"
                    class="btn btn-primary btn-sm">Edit Bundle
            </button>
            <button (click)="openCreateEvents(bundle)" style="float:right; margin-left: 10px;"
                    class="btn btn-success btn-sm">Create Events
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
