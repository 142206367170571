<div class="container" style="margin-top: 70px">
  <div class="jumbotron">
    <h1 class="display-5">Welcome to The ReTrader Admin Panel!</h1>
    <div class="lead">
      This is the new and improved ReTrader Admin Panel for 2020. Here you can
      modify the ReTrader app.
    </div>
    <hr class="my-1" />
    <p>
      If you're looking for the legacy admin panel for game creation the link
      can be found below.
    </p>
    <div class="container">
      <a
        class="btn btn-danger btn-lg"
        href="/legacy/admin"
        role="button"
        style="margin-left: 10px"
        >Legacy Admin Panel</a
      >
    </div>
  </div>
</div>
