<div *ngIf="updateEdit" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">
            Editing Event:<br />{{ gameToBeEditedTitle }}
          </h2>
          <button type="button" class="btn btn-danger" (click)="closeEditing()">
            Close
          </button>
        </div>
        <div class="modal-body">
          <form
            #GameUpdateForm="ngForm"
            (ngSubmit)="updateGame(GameUpdateForm)"
          >
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Event Name: </label>
              <div class="col-sm-9">
                <input
                  [ngClass]="{
                    'is-invalid': name.errors && (name.dirty || name.touched)
                  }"
                  #name="ngModel"
                  required
                  minLength="4"
                  maxLength="39"
                  name="name"
                  class="form-control"
                  type="text"
                  appForbiddenName="Default"
                  [(ngModel)]="editGame.title"
                />
                <small id="titleHelp" class="form-text text-muted"
                  >Example: Amplify 18/04/20 - OPEC Meeting 09/04/20</small
                >

                <div [hidden]="!name.errors?.required" class="invalid-feedback">
                  Event name is required.
                </div>
                <div
                  [hidden]="!name.errors?.minLength"
                  class="invalid-feedback"
                >
                  Event name must be at least 4 characters long.
                </div>
                <div
                  [hidden]="!name.errors?.maxLength"
                  class="invalid-feedback"
                >
                  Event name must be max 40 characters long.
                </div>
                <div
                  [hidden]="!name.errors?.forbiddenName"
                  class="invalid-feedback"
                >
                  Event name cannot contain DEFAULT.
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Category: </label>
              <div class="col-sm-9">
                <select
                  name="category"
                  id="categorySelection"
                  class="custom-select"
                  [(ngModel)]="editGame.category_id"
                >
                  <option *ngFor="let cat of categories" value="{{ cat.id }}">
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Position in Category:
              </label>
              <div class="col-sm-9">
                <input
                  [ngClass]="{
                    'is-invalid': orderId.errors && orderId.touched
                  }"
                  #orderId="ngModel"
                  required
                  min="1"
                  name="orderID"
                  class="form-control"
                  type="number"
                  appForbiddenName="-"
                  [(ngModel)]="editGame.order_id"
                />
                <div
                  [hidden]="!orderId.errors?.required"
                  class="invalid-feedback"
                >
                  In-App position is required.
                </div>
                <div [hidden]="!orderId.errors?.min" class="invalid-feedback">
                  In-App position must be greater than 0.
                </div>
                <div
                  [hidden]="!orderId.errors?.forbiddenName"
                  class="invalid-feedback"
                >
                  In-App position cannot be negative.
                </div>
              </div>
            </div>
            <div class="form-group row" *ngIf="!isScheduled(editGame)">
              <label class="col-sm-3 col-form-label">Visibility: </label>
              <div class="col-sm-9">
                <select
                  name="visibility"
                  id="visibilitySelection"
                  class="custom-select"
                  [(ngModel)]="editGame.visible"
                >
                  <option value="1">Show</option>
                  <option value="0">Hide</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Scheduled: </label>
              <div class="col-sm-9">
                <select
                  name="scheduled"
                  id="scheduled"
                  class="custom-select"
                  [(ngModel)]="editGame.scheduled"
                >
                <option value="1">Event to be scheduled</option>
                <option value="0">Event not to be scheduled</option>>
                </select>
              </div>
            </div>
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input value="Update" type="submit" class="btn btn-success" />
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="updateViewEvent" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">
            Selected Event:<br />{{ viewEvent.title }}
          </h2>
          <button type="button" class="btn btn-danger" (click)="closeEvent()">
            Close
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Date Event Created: </label>
              <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext"
                       value="{{viewEvent.date_added | date: 'dd/MM/yyyy'}}">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Category: </label>
              <div class="col-sm-9">
                <div *ngIf="viewEvent.category_id == null; else loadCategory">
                  <input
                    type="text"
                    readonly
                    class="form-control-plaintext"
                    value="No Category Assigned"
                  />
                </div>
                <ng-template #loadCategory>
                  <div *ngFor="let cats of categories">
                    <div *ngIf="viewEvent.category_id == cats.id">
                      <input
                        type="text"
                        readonly
                        class="form-control-plaintext"
                        value="{{ cats.name }}"
                      />
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Position in Category: </label>
              <div class="col-sm-9">
                <input
                  type="number"
                  readonly
                  class="form-control-plaintext"
                  value="{{ viewEvent.order_id }}"
                />
              </div>
            </div>
            <hr>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Game Cover Image: </label>
              <div class="col-sm-9">
                <img
                  src="https://{{ cloudfrontOpenURL }}/games/{{
                    viewEvent.image
                  }}"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Briefing Text: </label>
              <div class="col-sm-9">
                <textarea
                  autosize
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  value="{{ viewEvent.briefing }}"
                >
                </textarea>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Review Text: </label>
              <div class="col-sm-9">
                <textarea
                  autosize
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  value="{{ viewEvent.review }}"
                >
                </textarea>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Game Data Path: </label>
              <div class="col-sm-9">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  value="{{ viewEvent.data_file_cdn_object }}"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Briefing Video Path:
              </label>
              <div class="col-sm-9">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  value="{{ viewEvent.briefing_cdn_object }}"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Review Video Path: </label>
              <div class="col-sm-9">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  value="{{ viewEvent.review_cdn_object }}"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Visibility: </label>
              <div class="col-sm-9">
                <div *ngIf="viewEvent.visible == 1; else notVisible">
                  <input
                    type="text"
                    readonly
                    class="form-control-plaintext"
                    value="Visible"
                  />
                </div>
                <ng-template #notVisible>
                  <div *ngIf="viewEvent.visible == 0">
                    <input
                      type="text"
                      readonly
                      class="form-control-plaintext"
                      value="Invisible"
                    />
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Speed: </label>
              <div class="col-sm-9">
                <input type="number" readonly class="form-control-plaintext" value="{{viewEvent.speed}}">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Max Results: </label>
              <div class="col-sm-9">
                <input
                  type="number"
                  readonly
                  class="form-control-plaintext"
                  value="{{ viewEvent.max_results_to_show }}"
                />
              </div>
            </div>
            <div
              *ngFor="let eventDetails of selectedEventEvents; let i = index"
              [attr.data-index]="i"
            >
              <hr />
              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >News {{ i + 1 }} Text:
                </label>
                <div class="col-sm-9">
                  <textarea
                    autosize
                    type="text"
                    readonly
                    class="form-control-plaintext"
                    value="{{ eventDetails.text }}"
                  >
                  </textarea>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >News {{ i + 1 }} Time:
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    readonly
                    class="form-control-plaintext"
                    value="{{ eventDetails.event_time }}"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >News {{ i + 1 }} Clip Path:
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    readonly
                    class="form-control-plaintext"
                    value="{{ eventDetails.cdn_object }}"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="leaderboardOpen" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Leaderboard for Event:<br />{{ leaderboardTitle }}
          </h4>
          <div>
          <button
            type="button"
            class="btn btn-primary mr-2"
            (click)="exportExcel(leaderboardTitle)"
          >
            Download Results
          </button>
          <button
            type="button"
            class="btn btn-danger"
            (click)="closeLeaderboard()"
          >
            Close
          </button>
          </div>

        </div>
        <div class="modal-body">
          <table class="table table-striped" id="results-table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">PnL</th>
                <th scope="col">Date Played</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let usr of viewLeaderboard; let i = index">
                <tr>
                  <td>{{ i + 1 }}</td>
                  <td>{{ usr.first_name + " " + usr.last_name }}</td>
                  <td>${{ usr.points }}</td>
                  <td>{{ usr.date | date: "dd/MM/yyyy" }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isSchedulingGame" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Scheduling Game</h2>
        </div>
        <div class="modal-body">
          <form #SchedulingForm="ngForm" (ngSubmit)="scheduleEvent()">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Event start date/time:
              </label>
              <div class="col-sm-9">
                <input
                  #startDatetime="ngModel"
                  name="startDatetime"
                  type="datetime-local"
                  required
                  [(ngModel)]="schedulingDatetimes.startDatetime"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Event end date/time:
              </label>
              <div class="col-sm-9">
                <input
                  #endDatetime="ngModel"
                  name="endDatetime"
                  type="datetime-local"
                  required
                  [(ngModel)]="schedulingDatetimes.endDatetime"
                />
              </div>
            </div>
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input
                    value="Schedule"
                    type="submit"
                    class="btn btn-success"
                    [disabled]="!SchedulingForm.form.valid"
                  />
                </li>
                <li>
                  <button
                    type="button"
                    class="btn btn-danger"
                    (click)="closeScheduling()"
                  >
                    Close
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isCancellingEvent" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Confirm Remove Scheduling</h2>
        </div>
        <div class="modal-body">
          <form #updateGroupDeleteForm="ngForm" (ngSubmit)="cancelScheduledEvent(idToBeCancelled)">
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input value="Yes" type="submit" class="btn btn-success">
                </li>
                <li>
                  <button class="btn btn-danger" (click)="closeCancelEvent()">No</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" style="margin-top: 70px">
  <div class="row">
    <div class="col-md-auto">
      <h1>Events Page</h1>
      <p>
        An event is when a game is replicated to be used for an event creating a
        new leaderboard.
      </p>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label">Filter by Name: </label>
    <div class="col-sm-9">
      <input
      type="text"
      class="form-control"
      [(ngModel)]="searchText"
      placeholder="Search..."
      (change)="returnSearch()"
    />
    </div>
    <label class="col-sm-3 col-form-label mt-1">Filter by Category: </label>
    <div class="col-sm-9 mt-1">
      <select
        name="category"
        id="categorySelection"
        class="custom-select"
        [(ngModel)]="filterCategoryId"
      >
        <option value="-1">All Categories</option>
        <option *ngFor="let cat of categories" value="{{ cat.id }}">
          {{ cat.name }}
        </option>
      </select>
    </div>
    <div class="col-md-12 text-right">
      <button
        (click)="assignSubmitOpen()"
        class="btn btn-primary mt-1"
        >
        Submit
      </button>
    </div>
  </div>

  <div *ngIf="updateSubmitOpen">
    <div class="row mt-4">
      <div
        *ngFor="let game of pageOfItems"
        style="
          border-bottom: 5px solid rgb(255, 255, 255);
          border-left: 5px solid rgb(255, 255, 255);
          border-right: 5px solid rgb(255, 255, 255);
          border-top: none;
        "
        class="col-6 mb-4"
      >
        <div
          class="card"
          style="
            border: 1px solid #adabab;
            background-color: #414141;
            color: white;
          "
        >
          <img
            class="card-img-top"
            src="https://{{ cloudfrontOpenURL }}/games/{{ game.image }}"
            alt="No Image Found"
          />
          <div style="padding: 30px">
            <h4>
              {{ game.title }}
            </h4>
            <button
            (click)="openLeaderboard(game)"
            style="float: right;"
            class="btn btn-primary btn-sm"
          >
            View Leaderboard
          </button>
            <p><strong>Game ID:</strong> {{game.id}}</p>
            <p><strong>Date Event Created:</strong> {{game.date_added | date: 'dd/MM/yyyy'}}</p>
            <p *ngIf="!isScheduled(game)"><strong>Visibility:</strong> {{game.visible === 0 ? "Hidden" : "Visible"}}</p>
            <p *ngIf="isScheduled(game)"><strong>Scheduled:</strong> Visible during scheduled time</p>
            <div *ngIf="isEventScheduled(game)">
              <p>
                <strong>Start Date/Time:</strong>
                {{ convertToDateString(game.start_time) }}
              </p>
              <p>
                <strong>End Date/Time:</strong>
                {{ convertToDateString(game.end_time) }}
              </p>
            </div>
            <div>
              <div *ngIf="game.category_id == null; else loadCategory">
                No Category Assigned
              </div>
              <ng-template #loadCategory>
                <div *ngFor="let cats of categories">
                  <div *ngIf="game.category_id == cats.id">
                    <p><strong>Category: </strong>{{ cats.name }}</p>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="container">
              <button
                (click)="editingGame(game)"
                style="float: right; margin-left: 10px"
                class="btn btn-primary btn-sm"
              >
                Edit Event Details
              </button>
              <button
                (click)="openEvent(game)"
                style="float: right; margin-left: 10px"
                class="btn btn-primary btn-sm"
              >
                View Event Details
              </button>
			        <button
                (click)="schedulingGame(game)"
                style="float: right; margin-left: 10px"
                class="btn btn-success btn-sm"
                *ngIf="isScheduled(game)"
              >
                Schedule Event
              </button>
              <div *ngIf="isEventScheduled(game)">
                <button
                  (click)="openCancelEvent(game.id)"
                  style="float: right; margin-top: 10px"
                  class="btn btn-danger btn-sm"
                >
                Remove Scheduling
              </button>
            </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <ul
        *ngIf="pager.pages && pager.pages.length"
        class="pagination justify-content-center"
      >
        <li
          [ngClass]="{ disabled: pager.currentPage === 1 }"
          class="page-item first-item"
        >
          <a (click)="firstPage()" class="page-link" role="clickable">First</a>
        </li>
        <li
          [ngClass]="{ disabled: pager.currentPage === 1 }"
          class="page-item previous-item"
        >
          <a (click)="previousPage()" class="page-link" role="clickable"
            >Previous</a
          >
        </li>
        <li
          *ngFor="let page of pager.pages"
          [ngClass]="{ active: pager.currentPage === page }"
          class="page-item number-item"
        >
          <a (click)="selectedPage(page)" class="page-link" role="clickable">{{
            page
          }}</a>
        </li>
        <li
          [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"
          class="page-item next-item"
        >
          <a (click)="nextPage()" class="page-link" role="clickable">Next</a>
        </li>
        <li
          [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"
          class="page-item last-item"
        >
          <a (click)="lastPage()" class="page-link" role="clickable">Last</a>
        </li>
      </ul>
    </div>
  </div>
</div>
