<div *ngIf="updateGroupCreate" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Create Group</h2>
        </div>
        <div class="modal-body">
          <form #updateGroupCreateForm="ngForm" (ngSubmit)="createNewGroup(updateGroupCreateForm)">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Group Name: </label>
              <div class="col-sm-9">
              <input
                [ngClass]="{'is-invalid': name.errors &&  (name.dirty || name.touched)}"
                #name="ngModel"
                required
                minlength="2"
                name="name"
                class="form-control"
                type="text"
                [(ngModel)]="newGroup.name">
              <div [hidden]="!name.errors?.required" class="invalid-feedback">
                Group name is required.
              </div>
              <div [hidden]="!name.errors?. minlength" class="invalid-feedback">
                Group name must be at least 4 characters long.
              </div>
            </div>
            </div>
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input value="Create" type="submit" class="btn btn-success">
                </li>
                <li>
                  <button class="btn btn-danger" (click)="closeCreateGroup()">Close</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="updateGroupEdit" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Edit Group:<br/>{{updateGroupTitle}}</h2>
        </div>
        <div class="modal-body">
          <form #updateGroupForm="ngForm" (ngSubmit)="updateGroup(updateGroupForm)">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Group Name: </label>
              <div class="col-sm-9">
              <input
                [ngClass]="{'is-invalid': name.errors && name.touched}"
                #name="ngModel"
                required
                minlength="2"
                name="name"
                class="form-control"
                type="text"
                [(ngModel)]="editGroup.name">
              <div [hidden]="!name.errors?.required" class="invalid-feedback">
                Group name is required.
              </div>
              <div [hidden]="!name.errors?. minlength" class="invalid-feedback">
                Group name must be at least 4 characters long.
              </div>
              </div>
            </div>
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input value="Update" type="submit" class="btn btn-success">
                </li>
                <li>
                  <button class="btn btn-danger" (click)="closeGroup()">Close</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="updateGroupDelete" class="cover">
  <div class="custom-modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Confirm Delete Group:<br/>{{updateGroupDeleteTitle}} ? </h2>
        </div>
        <div class="modal-body">
          <form #updateGroupDeleteForm="ngForm" (ngSubmit)="removeGroup(updateGroupDeleteForm)">
            <div class="form-group">
              <ul class="form-buttons">
                <li>
                  <input value="Yes" type="submit" class="btn btn-success">
                </li>
                <li>
                  <button class="btn btn-danger" (click)="closeDeleteConfirm()">No</button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" style="margin-top: 70px;">
  <div class="col-md-12 mb-4">
    <h1> Groups
      <button (click)="openCreateGroup()" style="float:right; margin-top: 10px"
              class="btn btn-primary btn-sm">Create New Group
      </button>
    </h1>
  </div>
  <div class="row mt-4">
    <div *ngFor="let grp of groups"
         style="border-bottom: 5px solid rgb(255, 255, 255); border-left: 5px solid rgb(255, 255, 255); border-right: 5px solid rgb(255, 255, 255); border-top: none;"
         class="col-6 mb-4">
      <div class="card" style="border: 1px solid #adabab; background-color: #414141; color: white;">
        <div style="padding:30px">
          <h4>
            {{grp.name}}
          </h4>
          <div class="container">
            <button (click)="openDeleteConfirm(grp)" style="float:right; margin-left: 10px;"
                    class="btn btn-danger btn-sm">Delete Group
            </button>
            <button (click)="openEditGroup(grp)" style="float:right; margin-left: 10px;"
                    class="btn btn-primary btn-sm">Edit Group
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
