import { FlashMessagesService } from "angular2-flash-messages";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export default class NotificationService {
  constructor(private flashMessage: FlashMessagesService) {};

  public notifySuccess(text:string) {
    this.flashMessage.show(text, {
      cssClass: 'alert-success', timeout: 4000
    });
  }

  public notifyError(text: string) {
    this.flashMessage.show(text, {
      cssClass: 'alert-danger', timeout: 4000
    });
  }
}