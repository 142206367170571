import { Injectable } from '@angular/core';
import { ApiService } from "../services/api.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public authState = false;

  constructor(
    private api: ApiService,
    private router: Router,
  ) { }

  public isAuthenticated(){
    return this.authState;
  }

  public async loginService(paramsToSend: any){
    return new Promise((resolve, reject) =>{
      this.api.getHashCheckUser(paramsToSend).subscribe(async (res) => {
        if (res) {
          this.router.navigate(['dashboard']);
          this.authState = true;
          reject();
        } else if (!res) {
          this.authState = false;
          resolve();
        } else {}
      })
    })
  };


  public logout(){
    this.router.navigate(['login']);
    this.authState = false;
  }


}
