import {Pipe, PipeTransform} from '@angular/core';
import {LowerCasePipe} from '@angular/common';

@Pipe({
  name: 'UserFilter'
})
export class SearchPipe implements PipeTransform {
  transform(value: any[], args: string): any[] {
    if(!value) return [];
    if(!args) return value;

    args = args.toLowerCase();
    return value.filter( it => {
      return it.toLowerCase().includes(args);
    })
  }
}
