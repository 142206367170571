import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import NotificationService from '../../services/notification.service';
import { Clone } from "../../models/Clone";

@Component({
  selector: "app-game",
  templateUrl: "./game.component.html",
  styleUrls: ["./game.component.css"],
})
export class GameComponent implements OnInit {
  @ViewChild("gameForm") form: any;
  public cloudfrontOpenURL: any;

  public gameForm;

  // From parent
  @Input() action;
  @Input() selectedGame;
  @Input() categories;
  @Input() close;
  public creating = false;

  public ACTIONS = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    VIEW: "VIEW",
    DUPLICATE: "DUPLICATE"
  };

  // Properties
  public id: number = 0;
  public title: string = "";
  public speed: number = 0;
  public type: number = 0;
  public price_band: string = "";
  public image: string = "";
  public homepage_image: string = null;
  public briefing: string = "";
  public review: string = "";
  public data_file_cdn_object: string = "";
  public briefing_cdn_object: string = "";
  public review_cdn_object: string = "";
  public visible: number = 0;
  public random: number = 0;
  public max_results_to_show: number = 0;

  // Arrays
  public events: any = [];
  public openContent: Array<File> = [];
  public closedContent: Array<File> = [];

  constructor(
    private api: ApiService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.gameForm = new FormGroup({
      name: new FormControl(this.title, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(39),
      ]),
      imagePath: new FormControl(this.image, [Validators.required]),
      briefingDesc: new FormControl(this.briefing, [Validators.required]),
      reviewDesc: new FormControl(this.review, [Validators.required]),
      gamePath: new FormControl(this.data_file_cdn_object, [
        Validators.required,
      ]),
      briefingPath: new FormControl(this.briefing_cdn_object, [
        Validators.required,
      ]),
      reviewPath: new FormControl(this.review_cdn_object, [
        Validators.required,
      ]),
      resultsMax: new FormControl(this.max_results_to_show, [
        Validators.required,
        Validators.min(1),
      ]),
    });

    this.getcloudfrontOpenURL();

    // If an already created game is selected for editing or viewing, we preset the fields for the game
    if (this.selectedGame) {
      Object.entries(this.selectedGame).forEach(([k, v]) => {
        this[k] = v;
      });

      this.getGameEvents(this.id);
    }
  }

  addEvent() {
    let newEvent = {};
    this.events.push(newEvent);
  }

  deleteEvent() {
    this.events.pop();
  }

  selectContent(
    field: string,
    content: HTMLInputElement,
    closed: boolean,
    eventIndex: number = -1
  ) {
    const file: File = content.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", (e: any) => {
      if (eventIndex >= 0) {
        // If the uploaded content is for an event (i.e. it has an index >= 0), update that event field
        this.events[eventIndex][field] = `${this.title.replace(/\s+/g, "").trim().toLowerCase()}/${file.name.replace(/\s+/g, "").trim()}`;
      } else {
        // If the uploaded content is for the game itself, update the relevant game field
        this[field] = `${this.title.replace(/\s+/g, "").trim().toLowerCase()}/${file.name.replace(/\s+/g, "").trim()}`;
      }
    });

    if (file) {
      reader.readAsDataURL(file);
      if (closed) {
        this.closedContent.push(file);
      } else {
        this.openContent.push(file);
      }
    }
  }

  uploadMedia(media: Array<File>, closed: boolean) {
    this.api.uploadMedia(this.title.replace(/\s+/g, "").trim().toLowerCase(), media, closed).subscribe(
      (response) => {
        this.notificationService.notifySuccess("Files successfully uploaded.");
      },
      (err) => {
        this.notificationService.notifyError("Files failed to upload: " + err.text);
      }
    );
  }
  getcloudfrontOpenURL() {
    return this.api.getCloudfrontOpenURL().subscribe(async (res) => {
      // @ts-ignore
      this.cloudfrontOpenURL = res.URL;
    });
  }

  convertPropertiesToGameObject() {
    return {
      id: this.id,
      title: this.title,
      speed: this.speed,
      type: this.type,
      price_band: this.price_band,
      image: this.image,
      homepage_image: this.homepage_image,
      briefing: this.briefing,
      review: this.review,
      data_file_cdn_object: this.data_file_cdn_object,
      briefing_cdn_object: this.briefing_cdn_object,
      review_cdn_object: this.review_cdn_object,
      visible: this.visible,
      random: this.random,
      max_results_to_show: this.max_results_to_show,
    };
  }

  isViewAction() {
    return this.action === this.ACTIONS.VIEW;
  }

  isDuplicateAction() {
    return this.action === this.ACTIONS.DUPLICATE;
  }

  /* BUTTON FUNCTIONS */

  // "Create New Game"
  createNewGame({ value, valid }: { value: Clone; valid: boolean }) {
    this.creating = true;
    if (!valid) {
      this.notificationService.notifyError("Please fill out the form correctly");
      return;
    }
    if (this.openContent.length > 0) {
      this.uploadMedia(this.openContent, false);
    }
    if (this.closedContent.length > 0) {
      this.uploadMedia(this.closedContent, true);
    }
    return this.api
      .createNewGame(this.convertPropertiesToGameObject())
      .subscribe(async (res: { insertId; error }) => {
        if (res.error) {
          this.notificationService.notifyError(res.error.message);
        }
        this.id = res.insertId;
        this.createGameEvents();
        this.close();
      });
  }

  // "Edit Game"

  editGame({ value, valid }: { value: Clone; valid: boolean }) {
    if (!valid) {
      this.notificationService.notifyError("Please fill out the form correctly");
      return;
    }
    if (this.openContent.length > 0) {
      this.uploadMedia(this.openContent, false);
    }
    if (this.closedContent.length > 0) {
      this.uploadMedia(this.closedContent, true);
    }
    return this.api
      .editGame(this.convertPropertiesToGameObject())
      .subscribe(async (res: { error }) => {
        if (res.error) {
          this.notificationService.notifyError(res.error.message);
        } else {
          if (this.createGameEvents() && this.updateGameEvents()) {
            this.notificationService.notifySuccess("Updated game and added all events");
          }
          this.close();
        }
      });
  }

  // Event functions
  createGameEvents() {
    let checker = [];
    this.events.forEach((newNewsItem) => {
      if (!newNewsItem.isOld || this.isDuplicateAction()) {
        newNewsItem.game_id = this.id;
        delete newNewsItem.isOld;
        delete newNewsItem.id;
        return this.api.createGameEvent(newNewsItem).subscribe(async (res) => {
          if (res) {
            checker.push(true);
          } else {
            checker.push(false);
          }
        });
      }
    });
    return !checker.includes(false);
  }

  updateGameEvents() {
    let checker = [];
    this.events.forEach((oldNewsItem) => {
      if (oldNewsItem.isOld) {
        oldNewsItem.game_id = this.id;
        delete oldNewsItem.isOld;
        return this.api.updateGameEvent(oldNewsItem).subscribe(async (res) => {
          if (res) {
            checker.push(true);
          } else {
            checker.push(false);
          }
        });
      }
    });
    return !checker.includes(false);
  }

  deleteGameEvent() {
    let event = this.events.pop();
    if (event && event.isOld) {
      return this.api.deleteGameEvent(event).subscribe(async (res) => {
        this.notificationService.notifySuccess("Event deleted successfully");
      });
    }
  }

  getGameEvents(game) {
    return this.api.getTemplateGameEvents(game).subscribe(async (res: any) => {
      res.forEach((gameEvent) => {
        this.events.push({ isOld: true, ...gameEvent });
      });
      this.events = this.events.sort((e) => -e.id);
    });
  }

  editEvent() {
    this.action = this.ACTIONS.EDIT;
  }
}
